import { ApiError } from "../../models/layout";
import { PolyLine, Trip, TripCar, TripRoute, TripUser, WayPoint } from "../../models/trips";
import { RequestAction } from "../../statetree/request";

export class TripsRequest extends RequestAction<TripsResult, {}, {}, TripsDto> {

    constructor(nextPageToken: string | undefined, userId: string | undefined, actual: boolean | undefined) {
        const query: any = {};
        if (nextPageToken) query.nextPageToken = nextPageToken;
        else {
            if (userId) query.userId = userId;
            if (actual !== undefined) query.actual = actual;
        }
        super("GET", "/api/admin/trips", query);
    }

    fromDto(dto: TripsDto): TripsResult {
        return {
            items: dto.items.map(d => {
                const driver = d.driver ? new TripUser(d.driver.id, d.driver.name): undefined;
                const car = d.car ? new TripCar(d.car.id, d.car.brand, d.car.model, d.car.year, d.car.color, d.car.number, d.car.image, d.car.default): undefined;
                const clients = (d.clients || []).map(c => new TripUser(c.id, c.name));
                const route = new TripRoute(
                    d.route.info,
                    (d.route.waypoints || []).map(w => new WayPoint(w.time, w.name1, w.name2, w.label, w.type, w.color, w.lat, w.lon, w.image, w.letter)),
                    (d.route.polylines || []).map(p => new PolyLine(p.type, p.color, p.polyline, p.hint)),
                    d.route.distTo,
                    d.route.distRoute,
                    d.route.distAfter
                    )
                return new Trip(d.id, new Date(d.created), d.terminationStatus, driver, car, clients, route);
            }),
            token: dto.nextPageToken || '' 
        }
    }
    mapError(code: string, msg: string): Error | undefined {
        return new ApiError(code, msg);
    }
}

interface TripsResult {
    items: Trip[];
    token: string;
}

interface TripsDto {
    items: {
        id: string;
        created: Date;
        terminationStatus: string;
        driver: {
            id: string;
            name: string;
        };
        car: {
            id: string;
            brand: string;
            model: string;
            year: number;
            color: {
                colorName: { [lang: string]: string };
                colorDecimal: number;
            },
            number: string;
            image: string;
            default: boolean;
        },
        clients:
        {
            id: string;
            name: string;
        }[];
        route: {
            info: string;
            waypoints: {
                time: string;
                name1: string;
                name2: string;
                label: string;
                type: string;
                color: string;
                lat: number;
                lon: number;
                image: string;
                letter: string;
            }[],
            polylines: {
                type: string;
                color: string;
                polyline: string;
                hint: string;
            }[];

            distTo: string;
            distRoute: string;
            distAfter: string;
        }
    }[];
    nextPageToken?: string;
}