import React from "react";
import styles from './layout.module.scss';
import { Icon } from "../../uikit/icon";
import { useTranslation } from "react-i18next";
import {LangMenu} from './langMenu';
import { AppSettings, ColorThemes, ToolBarTab } from "../../models/layout";
import { useChannel, useDispatch } from "../../statetree/hooks";
import { RootVmActions } from "../rootVm";

interface ToolBarProps {
  activeTab?: ToolBarTab;
}

export const ToolBar: React.FunctionComponent<ToolBarProps> = (props) => {
  const {t} = useTranslation();
  const settings = useChannel(AppSettings);
  const dispatch = useDispatch();
  const color = settings.colorTheme;
  return <div className={styles.toolbar}>
    <ul className={styles.tabs} >
      {props.activeTab && <li className={styles.active}>
        <span className={styles.block}>
          <span className={styles.title}>{t(props.activeTab.titleId)}</span>
          <Icon>chevron_down</Icon>
        </span>
      </li>}
    </ul>
    <ul className={styles.actions}>
      <li><Icon button={true} onClick={handleThemeChange}>{color === ColorThemes.Light ? 'night' : 'sun'}</Icon></li>
    </ul>
    <LangMenu />
  </div>

  function handleThemeChange() {
    dispatch(new RootVmActions.SetColorTheme(color === ColorThemes.Light ? ColorThemes.Dark: ColorThemes.Light));
  }
}