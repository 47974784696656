import React, { useState } from "react";
import usePortal from "react-cool-portal";
import { useTranslation } from "react-i18next";
import { isEmail } from "../../services/utils/validators";
import { view } from "../../statetree/view";
import { Button } from "../../uikit/button";
import { Spinner } from "../../uikit/spinner";
import { TextField } from "../../uikit/textField";
import styles from './login.module.scss';
import { LoginState } from "./loginState";
import { LoginVm, LoginVmActions } from "./loginVm";

export const OrderList = view(LoginState, LoginVm, [], (props) => {
    const { vm, dispatch } = props;
    const { Portal } = usePortal({
        defaultShow: true,
        internalShowHide: false
    });
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return <Portal>
        <div className={styles.back}>
            <div className={styles.login + ' popup_shadow'}>
                <h3>{t('auth.wellcom')}</h3>
                <TextField className={styles.input} placeholder={t('auth.email')} value={email} onChange={setEmail} />
                <TextField className={styles.input} type="password" placeholder={t('auth.password')} value={password} onChange={setPassword} />
                <div className={styles.btns}>
                    <Button kind="primary" disabled={!canLogin()} onClick={handleLogin}>{t('uikit.buttons.send')}</Button>
                </div>
                {vm.inProgress && <Spinner className={styles.spinner} />}
                {vm.loginError && <div className={styles.loginError}>{vm.loginError}</div>}
            </div>
        </div>
    </Portal>

    function canLogin() {
        return isEmail(email || '') || password || !vm.inProgress;
    }
    function handleLogin() {
        dispatch(new LoginVmActions.Login(email, password));
    }
});