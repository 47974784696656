import { ColorThemes } from "../models/layout";
import { User } from "../models/user";
import { Action } from "../statetree/action";

export class RootVm {
    constructor(
        readonly user: User = new User(),
        readonly path: string = ''
    ) {
        console.log(`RootVm User=${user}, page=${path}`)
    }
}

export namespace RootVmActions {
    export class Logout extends Action<RootVm> { }
    export class SetColorTheme extends Action<RootVm> {
        constructor(
            readonly color: ColorThemes = ColorThemes.Light
        ) { super(); }
    }
}