import { Action } from "../../statetree/action";

export class LoginVm {
    constructor(
        readonly loginError = "",
        readonly inProgress = false
    ) { }

    beginLogin(): LoginVm {
        return new LoginVm("", true);
    }

    endLogin(error: string = ""): LoginVm {
        return new LoginVm(error, false);
    }
}

export namespace LoginVmActions {

    export class Login extends Action<LoginVm> {
        constructor(
            readonly email = "",
            readonly password = "",
        ) { super(); }
    }
}