import * as React from 'react';
import styles from './menu.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '../icon';

const cx = classNames.bind(styles);

export interface MenuItem {
    id: string;
    icon?: string | JSX.Element;
    text: string | JSX.Element;
}

export interface MenuItemProps {
    item: MenuItem;
    checked?: boolean;
    onSelect: (item: MenuItem) => void;
}

interface MenuProps {
    items: MenuItem[];
    className?: string;
    selected?: string;
    onSelect?: (item: string) => void;
}

export const MenuItemComp: React.FunctionComponent<MenuItemProps> = (props) => {
    const icon = typeof (props.item.icon) === "string" ? <Icon className={styles.icon}>{props.item.icon}</Icon> : props.item.icon;
    return <div className={styles.item} onClick={() => props.onSelect(props.item)}>
        {icon}
        <div className={styles.text}>{props.children}</div>
        <div className={styles.check}>{props.checked && <Icon>check</Icon>}</div>
    </div>
}

export const Menu: React.FunctionComponent<MenuProps> = (props) => {

    return <div className={cx('menu', props.className, { 'show-check': props.selected })}>
        {props.items.map(item => <MenuItemComp item={item} key={item.id} onSelect={handleItemSelect} checked={props.selected === item.id} >{item.text}</MenuItemComp>)}
    </div>
    function handleItemSelect(item: MenuItem) {
        if (props.onSelect)
            props.onSelect(item.id);
    }
}

