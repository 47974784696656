import './index.scss';
import reportWebVitals from './reportWebVitals';
import { AppTree } from './app/appTree';

const requireComponent = (require as any).context(
  './components', // components root
  true, // look subfolders
  /\w+\.(tsx)$/ //regex for files
);
requireComponent.keys().forEach((fileName: string) => requireComponent(fileName));

new AppTree().run('root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
