import { Client } from "../../models/client";
import { Action } from "../../statetree/action";

export class ClientsVm {
    constructor(
        readonly clients: Client[] = [],
        readonly token: string = '',
        readonly inProgress: boolean = false,
        readonly error: string = ''
    ) { }
    beginLoad() {
        return new ClientsVm(this.clients, this.token, true);
    }
    endLoad(clients: Client[], canLoadNext: string) {
        const _clients = this.token ? this.clients.concat(clients) : clients;
        return new ClientsVm(_clients, canLoadNext, false);
    }
    writeError(error: string) {
        return new ClientsVm(this.clients, this.token, false, error);
    }
}

export namespace ClientsVmActions {
    export class Load extends Action<ClientsVm> {
        constructor (
            readonly isReload: boolean = false,
        ) {super();}
    }
}