import * as React from 'react';
import styles from './checkbox.module.scss';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
const cx = classnames.bind(styles);

interface CheckboxProps {
    className?: string;
    checked?: boolean;
    label?: string;
    labelId?: string;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
    let icon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        {
            props.checked ? <path fillRule="evenodd" clipRule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4ZM4.29297 10.7071L8.50008 14.9142L16.7072 6.70712L15.293 5.29291L8.50008 12.0858L5.70718 9.29291L4.29297 10.7071Z" /> :
                <path fillRule="evenodd" clipRule="evenodd" d="M16 3H4C3.44772 3 3 3.44772 3 4V16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V4C17 3.44772 16.5523 3 16 3ZM4 0C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H4Z" />
        }
    </svg>

    const { t } = useTranslation();
    return <div className={cx(props.className, 'checkbox', { disabled: props.disabled, checked: !!props.checked })} onClick={handleClick} tabIndex={0} onKeyDown={handleKeyDown}>
        {icon}
        {props.label || props.labelId ? <label>{props.labelId ? t(props.labelId) : (props.label || '')}</label> : undefined}
    </div>

    function handleClick() {
        if (props.onChange && !props.disabled) props.onChange(!props.checked);
    }
    function handleKeyDown(event: React.KeyboardEvent) {
        console.log('event', event)
        if (event.key === ' ') {
            handleClick();
        }
    }
}