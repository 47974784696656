import * as React from 'react';
import styles from './textField.module.scss';
import classnames from 'classnames/bind';
import { Icon } from '../icon';
const cx = classnames.bind(styles);

interface TextFieldProps {
    className?: string;
    value?: string;
    type?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    icon?: string;
    actions?: string[];
    onAction?: (action: string) => void;
    readOnly?: boolean;
}

export const TextField: React.FunctionComponent<TextFieldProps> = (props) => {

    return <div className={cx('textField', props.className, { disabled: props.disabled })}>
        {props.icon && <Icon className={styles.icon}>{props.icon}</Icon>}
        <input
            type={props.type || 'text'}
            value={props.value}
            onChange={handleOnChange}
            disabled={props.disabled}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            autoComplete={'none'}
        />
        {props.actions && <div className={styles.actions}>
            {props.actions.map(a => <Icon className={styles.aIcon} key={a} onClick={event => handleActionClick(event, a)}>{a}</Icon>)}
        </div>}
    </div>
    function handleActionClick(event: React.MouseEvent, action: string) {
        if (props.onAction && !props.disabled)
            props.onAction(action)
    }
    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (props.onChange) props.onChange(event.target.value);
    }

}
