import React, { useEffect, useState } from 'react';
import styles from './table.module.scss';
import classnames from 'classnames/bind';
import { Icon } from '../icon';
import { Button } from '../button';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { roundFloat } from '../../services/utils/convert';
import { formatCurrency } from '../../services/utils/currency';
import { useTranslation } from 'react-i18next';
import { DataController} from './dataController';

const cx = classnames.bind(styles);

export enum TableDataType {
    Custom = 0,
    String,
    Number,
    Currency,
    DateTime
}
export enum Sort {
    None = 0,
    Asc,
    Desc
}

export interface CurrentSorting {
    field: string;
    dataType: TableDataType;
    sort: Sort;
}

interface TableProps {
    className?: string;
    dataController: DataController;
    onRowSelect?: (row: any) => void;
}

export const Table: React.FunctionComponent<TableProps> = (props) => {
    const [columns, setColumns] = useState<Column[]>([]);
    const [sorted, setSorted] = useState<CurrentSorting | undefined>();
    const [selected, setSelected] = useState(-1);
    const { children } = props;
    const { t } = useTranslation();
    useEffect(() => {
        const _columns: Column[] = [];
        React.Children.forEach(children, element => {
            if (!React.isValidElement(element)) return;
            if (element.type === TableColumn) {
                _columns.push(new Column(
                    element.props.field,
                    element.props.title,
                    element.props.dataType,
                    element.props.canSort,
                    element.props.className,
                    element.props.format,
                    element.props.renderer
                ))
            }
            setColumns(_columns);
        })
    }, [children])

    return <React.Fragment>
        <table className={cx('table', props.className)} cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    {columns.map((col, idx) => <th key={`${idx}_${col.field}`} onClick={() => sortedChange(col)} className={cx(col.className)}>
                        <span>{col.title}</span>
                        {col.canSort && <Icon width={16} height={16} viewBox="0 0 16 16" className={cx('sort-indicator',
                            { sorted: sorted && col.field === sorted.field && col.sort !== Sort.None, asc: col.sort === Sort.Asc })}>sort_down</Icon>}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {props.dataController.getSorted(sorted).map((d, idx) => {
                    const { idField } = props.dataController;
                    let key = idField ? d[idField] : idx;
                    return <tr key={key} onClick={() => handleRowClick(d, key)} className={cx({ selected: key === selected })}>
                        {columns.map((col, idx) => <td key={`${idx}_${col.field}`} className={cx(col.className)}>
                            {col.renderCell(d)}
                        </td>)}
                    </tr>
                })}
            </tbody>
        </table>
        <div className={styles.btns}>
            {props.dataController.canNext ? <Button className={styles.loadBtn} kind="secondary" onClick={handleLoadMore}>{t('uikit.buttons.loadMore')}</Button> : undefined}
        </div>
    </React.Fragment>

    function handleRowClick(row: any, key: number) {
        setSelected(key);
        if (selected !== key && props.onRowSelect) props.onRowSelect(row);
    }
    function handleLoadMore() {
        if (props.dataController)
            props.dataController.loadMore();
    }
    function sortedChange(col: Column) {
        col.changeSorting();
        setSorted({
            field: col.field,
            dataType: col.dataType,
            sort: col.sort
        });
    }
}

interface ICellFormat {
    // For Number, Currency
    decimal?: number; // round to 
    fixed?: number;
    //Currency
    currency?: string;
    inCent?: boolean;
    // Date, Time, DateTime
    dateTimeFormat?: DateTimeFormatOptions
}
type CellRenderer = (data: any) => JSX.Element | undefined;
interface TableColumnProps {
    field: string;
    className?: string;
    title: string | JSX.Element;
    dataType: TableDataType;
    canSort?: boolean;
    format?: ICellFormat;
    renderer?: CellRenderer;
}

export const TableColumn: React.FunctionComponent<TableColumnProps> = (props) => {

    return <th>props.title</th>
}

export class Column {
    public sort: Sort = Sort.None;
    constructor(
        readonly field: string,
        readonly title: string,
        readonly dataType: TableDataType,
        readonly canSort: boolean | undefined,
        readonly className: string | undefined,
        readonly format: ICellFormat | undefined,
        readonly renderer: CellRenderer | undefined
    ) { }

    renderCell(data: any) {
        switch (this.dataType) {
            case TableDataType.String:
                return <span>{data[this.field]}</span>;
            case TableDataType.Number:
                if (data[this.field] === undefined)
                    return '';
                let n = +data[this.field];
                if (this.format && this.format.decimal !== undefined)
                    n = roundFloat(n, this.format.decimal);
                if (this.format && this.format.fixed !== undefined)
                    return <span>{n.toFixed(this.format.fixed)}</span>
                return <span>{n}</span>
            case TableDataType.Currency:
                let curr = +data[this.field];
                if (this.format && this.format.inCent)
                    curr = curr / 100;
                if (this.format && this.format.decimal !== undefined)
                    curr = roundFloat(curr, this.format.decimal);
                const currency = (this.format && this.format.currency && data[this.format.currency]) || 'USD';
                const fixed = this.format && this.format.fixed !== undefined ? this.format.fixed : 2;
                return <span>{formatCurrency(currency, curr, fixed)}</span>
            case TableDataType.DateTime:
                let dt: DateTime | undefined = undefined;
                let _d = data[this.field];
                if (_d) {
                    if (typeof (_d) === 'number')
                        dt = DateTime.fromMillis(_d);
                    else if (_d instanceof Date)
                        dt = DateTime.fromJSDate(_d);
                    else if (typeof _d === 'string')
                        dt = DateTime.fromISO(_d);
                    if (dt) {
                        const fm = (this.format && this.format.dateTimeFormat) || DateTime.DATE_FULL;
                        return dt.toLocaleString(fm);
                    }
                }
                return undefined;
            case TableDataType.Custom:
                if (this.renderer)
                    return this.renderer(data);
                return undefined;
            default:
                return <span>{data[this.field]}</span>
        }
    }
    changeSorting() {
        if (this.canSort) {
            switch (this.sort) {
                case Sort.None:
                    this.sort = Sort.Asc;
                    break;
                case Sort.Asc:
                    this.sort = Sort.Desc;
                    break;
                default:
                    this.sort = Sort.None;
            }
        } else
            this.sort = Sort.None;
    }
}

