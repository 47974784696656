import { ApiError } from "../../models/layout";
import { PolyLine, Trip, TripCar, TripRoute, TripUser, WayPoint } from "../../models/trips";
import { RequestAction } from "../../statetree/request";

export class TripRequest extends RequestAction<Trip, {}, {}, TripDto> {

    constructor(tripId: string) {
        super("GET", "/api/admin/trips/users/:tripId", { tripId });
    }

    fromDto(dto: TripDto): Trip {
        const driver = dto.driver ? new TripUser(dto.driver.id, dto.driver.name) : undefined;
        const car = dto.car ? new TripCar(dto.car.id, dto.car.brand, dto.car.model, dto.car.year, dto.car.color, dto.car.number, dto.car.image, dto.car.default) : undefined;
        const clients = (dto.clients || []).map(c => new TripUser(c.id, c.name));
        const route = new TripRoute(
            dto.route.info,
            (dto.route.waypoints || []).map(w => new WayPoint(w.time, w.name1, w.name2, w.label, w.type, w.color, w.lat, w.lon, w.image, w.letter)),
            (dto.route.polylines || []).map(p => new PolyLine(p.type, p.color, p.polyline, p.hint)),
            dto.route.distTo,
            dto.route.distRoute,
            dto.route.distAfter
        )
        return new Trip(dto.id, new Date(dto.created), dto.terminationStatus, driver, car, clients, route);
    }
    mapError(code: string, msg: string): Error | undefined {
        return new ApiError(code, msg);
    }
}

interface TripDto {
    id: string;
    created: Date;
    terminationStatus: string;
    driver: {
        id: string;
        name: string;
    };
    car: {
        id: string;
        brand: string;
        model: string;
        year: number;
        color: {
            colorName: { [lang: string]: string };
            colorDecimal: number;
        },
        number: string;
        image: string;
        default: boolean;
    },
    clients:
    {
        id: string;
        name: string;
    }[];
    route: {
        info: string;
        waypoints: {
            time: string;
            name1: string;
            name2: string;
            label: string;
            type: string;
            color: string;
            lat: number;
            lon: number;
            image: string;
            letter: string;
        }[],
        polylines: {
            type: string;
            color: string;
            polyline: string;
            hint: string;
        }[];

        distTo: string;
        distRoute: string;
        distAfter: string;
    }
}