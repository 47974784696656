import { ClientDetail } from "../../models/client";
import { Action } from "../../statetree/action";

export class ClientVm {
    constructor(
        readonly client: ClientDetail| undefined = undefined,
        readonly inProgress: boolean = false,
        readonly error: string = ''
    ) {
    }
    beginLoad() {
        return new ClientVm(this.client, true);
    }
    endLoad(client: ClientDetail) {
        return new ClientVm(client, false);
    }
    writeError(error: string) {
        return new ClientVm(this.client, false, error);
    }
}

export namespace ClientVmActions {
    export class Load extends Action<ClientVm> {
        constructor (
            readonly clientId: string = '',
        ) {super();}
    }
    export class OpenTrips extends Action<ClientVm> {
        constructor (
            readonly userId: string = '',
        ) {super();}
    }
}