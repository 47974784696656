import React, { Suspense } from 'react';
import { Spinner } from '../uikit/spinner';
import { initI18n } from '../services/i18n/init';
import { MainLayout } from './layout';
import { useViews } from '../statetree/hooks';
import { view } from '../statetree/view';
import { RootState } from './rootState';
import { RootVm, RootVmActions } from './rootVm';

initI18n();

export const Root = view(RootState, RootVm, [], (props) => {
    const {vm, dispatch} = props;
    const views = useViews([]);
    return <Suspense fallback={<Spinner />} >
        <React.StrictMode>
            <MainLayout user={vm.user} onLogOut={handleLogout} path={vm.path}>
                {views.map(view => <view.component key={view.key} />)}
            </MainLayout>
        </React.StrictMode>
    </Suspense >

    function handleLogout() {
        dispatch(new RootVmActions.Logout());
    }

});