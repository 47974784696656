import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import universalLanguageDetect from '@unly/universal-language-detector';
import config from './config.json';
import { Settings } from 'luxon';

export function initI18n() {
  const fileMask = `/i18n/{{lng}}.${config.timeId}.json`;

  const storedLang = localStorage.getItem('cdAdminLang');
  let lang: string;
  if (storedLang)
    lang = storedLang;
  else {
    lang = universalLanguageDetect({
      supportedLanguages: config.supportedLanguages, // Whitelist of supported languages, will be used to filter out languages that aren't supported
      fallbackLanguage: 'en', // Fallback language in case the user's language cannot be resolved
      errorHandler: (error, level, origin, context) => { // Optional - Use you own logger here, Sentry, etc.
        console.log('Language detect error handler:');
        console.error(error);
      },
    });
    localStorage.setItem('cdAdminLang', lang);
  }
  i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      backend: {
        loadPath: fileMask
      },
      //      debug: true,
      lng: lang,
      load: 'languageOnly',

      fallbackLng: "en",
      interpolation: {
        escapeValue: false
      }
    });
  Settings.defaultLocale = lang;
  return lang;

}