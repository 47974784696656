import styles from './log.module.scss';
import { Button } from '../../../uikit/button';
import { Spinner } from '../../../uikit/spinner';
import { useTranslation } from 'react-i18next';
import { view } from '../../../statetree/view';
import { TripLogState } from './tripLogState';
import { TripLogVm, TripLogVmActions } from './tripLogVm';
import { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export interface TripLogProps {
    tripId?: string;
}

export const TripLog = view<TripLogProps, TripLogVm>(TripLogState, TripLogVm, [], (props) => {
    const { t } = useTranslation();
    const { vm, dispatch } = props;
    useEffect(() => {
        if (props.tripId)
            dispatch(new TripLogVmActions.Load(props.tripId));
    }, [props.tripId])// eslint-disable-line 

    return <div className={styles.log}>
        <h2 className={styles.header}>{t('components.trips.log')}</h2>
        {vm.inProgress ? <div className={styles.spinner}><Spinner /></div> : <Scrollbars  autoHeight={true} autoHeightMax={600} ><ul className={styles.table} >
            {vm.items.map(item => <li key={item.index}>{item.value}</li>)}
        </ul></Scrollbars>}
        {vm.token ? <Button className={styles.loadBtn} kind="secondary" onClick={handleLoadNext}>{t('uikit.buttons.loadMore')}</Button> : undefined}
    </div>

    function handleLoadNext() {
        props.dispatch(new TripLogVmActions.Load(props.tripId))
    }
});