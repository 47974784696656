
export class Client {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly phone: string,
        readonly rating: number,
        readonly positiveValueCount: number,
        readonly numTrips: number,
        readonly created: number,
        readonly carsCount: number
    ) { }
}

export class ClientDetail {
    constructor(
        readonly id: string = '',
        readonly updated: number = 0,
        readonly account: ClientAccount= new ClientAccount(),
        readonly stats: ClientStats = new ClientStats(),
        readonly rating: ClientRating = new ClientRating(),
        readonly notifications: ClientNotification[] = [],
        readonly cars: {
            items: { id: string; }[];
            defaultId: string;
        } = {items: [], defaultId: ''}
    ){}
}

export class ClientAccount {
    constructor(
        readonly created: number = 0,
        readonly disabled: boolean = false,
        readonly country: string = '',
        readonly phone: string = '',
        readonly phoneE164: string = '',
        readonly name: string = '',
        readonly surname: string = '',
        readonly email: string = '',
        readonly emailCanonical: string = '',
        readonly emailNew: string = '',
        readonly avatar: string = '',
        readonly birthDate: number = 0,
        readonly gender: string = '',
        readonly facebookId: string = '',
        readonly facebookName: string = '',
        readonly appleId: string = '',
        readonly appleName: string = '',
        readonly appleEmail: string = '',
        readonly googleId: string = '',
        readonly googleName: string = '',
        readonly googleEmail: string = '',
    ) {}
}

export class ClientStats {
    constructor(
        readonly codeHash: string = '',
        readonly resetCodeAt: number = 0,
        readonly loginAt: number = 0,
        readonly logoutAt: number = 0,
        readonly numFeedbacksGiven: number = 0,
        readonly numTrips: number = 0,
    ) {}
};
export class ClientRating {
    constructor(
        readonly value: number = 0,
        readonly count: number = 0,
        readonly positiveValueCount: number = 0,
        readonly values: number[] = []
    ){}
};

export class ClientNotification {
    constructor(
        readonly id: number = 0,
        readonly type: number = 0,
        readonly tripId: string = '',
        readonly tripCountry: string = '',
        readonly requestId: string = '',
        readonly role: number = 0,
        readonly names: string[] = [],
        readonly ts: number = 0,
        readonly tripTs: number = 0,
        readonly tripName: string = '',
        readonly read: boolean = false
    ) {}
}