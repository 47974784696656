export enum ColorThemes {
    Light = 0,
    Dark = 1
}

export class AppSettings {
    constructor(
        readonly colorTheme: ColorThemes = ColorThemes.Light
    ) { }

    setColorTheme(color: ColorThemes) {
        if (document) {
            const body = document.body;
            if (body)
                body.className = color === ColorThemes.Light ? 'theme-light' : 'theme-dark';
        }
        return new AppSettings(color);
    }
}

export class MainDialog {
    constructor(
        readonly textId: string,
        readonly value: any
    ) { }
}


export class ToolBarTab {
    constructor(
        readonly titleId: string,
        readonly url: string
    ) { }
}

export class ApiError extends Error {
    constructor(
        readonly code: string,
        readonly errorMessage: string
    ) {
        super(errorMessage || code);
    }
}