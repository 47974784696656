import { handler, State } from "../../statetree/state";
import { LoginVm, LoginVmActions } from "./loginVm";
import { User } from '../../models/user';
import { LoginError, LoginRequest } from "./loginRequest";

export class LoginState extends State {

    onAttached() { }

    onDetached() { }

    @handler(LoginVmActions.Login)
    async onLogin(action: LoginVmActions.Login): Promise<void> {
        const location = window && window.location;
        this.put(LoginVm, this.get(LoginVm).beginLogin())
        let errorMessage: string | undefined;
        try {
            try {
                const user = await this.run(new LoginRequest(action.email, action.password));
                this.put(User, user);
                location.replace('/')
            } catch (err) {
                errorMessage = err instanceof LoginError ? err.message : `Login error: ${err.message}`
            }
        }
        finally {
            this.put(LoginVm, this.get(LoginVm).endLogin(errorMessage));
        }
    }
}