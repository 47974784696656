import { ApiError } from "../../../models/layout";
import { handler, State } from "../../../statetree/state";
import { TripLogRequest } from "./tripLogRequest";
import { TripLogVm, TripLogVmActions } from "./tripLogVm";

export class TripLogState extends State {

    private logItems: string[] = [];

    onAttached() {
    }

    onDetached() {
    }

    @handler(TripLogVmActions.Load)
    async onLoadLog(action: TripLogVmActions.Load): Promise<void> {
        this.put(TripLogVm, this.get(TripLogVm).beginLoad());
        try {
            const vm = this.get(TripLogVm);
            const nextPageToken = vm.token || '';
            const result = await this.run(new TripLogRequest(action.tripId, 25, nextPageToken));

            this.logItems.push(...result.items);
            const items = this.logItems.map((i, idx) => ({
                value: i,
                index: idx
            }))
            this.put(TripLogVm, this.get(TripLogVm).endLoad(items, result.nextPageToken));
        } catch (err) {
            const errorMessage = err instanceof ApiError ? err.message : `Login error: ${err.message}`
            this.put(TripLogVm, this.get(TripLogVm).writeError(errorMessage));
        }
    }
}