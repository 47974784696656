
export class TripUser {
    constructor(
        readonly id: string,
        readonly name: string
    ) { }
}
export class TripCar {
    constructor(
        readonly id: string,
        readonly brand: string,
        readonly model: string,
        readonly year: number,
        readonly color: {
            colorName: { [lang: string]: string };
            colorDecimal: number;
        },
        readonly number: string,
        readonly image: string,
        readonly _default: boolean
    ) { }
}
export class TripRoute {
    constructor(
        readonly info: string,
        readonly waypoints: WayPoint[],
        readonly polylines: PolyLine[],
        readonly distTo: string,
        readonly distRoute: string,
        readonly distAfter: string
    ) { }
}
export class WayPoint {
    constructor(
        readonly time: string,
        readonly name1: string,
        readonly name2: string,
        readonly label: string,
        readonly type: string,
        readonly color: string,
        readonly lat: number,
        readonly lon: number,
        readonly image: string,
        readonly letter: string
    ) { }
}
export class PolyLine {
    constructor(
        readonly type: string,
        readonly color: string,
        readonly polyline: string,
        readonly hint: string
    ) { }
}

export class Trip {
    constructor(
        readonly id: string,
        readonly created: Date,
        readonly terminationStatus: string,
        readonly driver: TripUser | undefined,
        readonly car: TripCar | undefined,
        readonly clients: TripUser[],
        readonly route: TripRoute
    ) { }
}
