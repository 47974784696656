import styles from './client.module.scss';
import { view } from "../../statetree/view";
import { ClientState } from "./clientState";
import { ClientVm, ClientVmActions } from "./clientVm";
import { Panel, PanelRow } from '../../uikit/panel';
import { Spinner } from '../../uikit/spinner';
import { Checkbox } from '../../uikit/checkbox';
import { DateTime } from 'luxon';
import { Rating } from '../../uikit/rating';
import React from 'react';
import { Notifications } from './notifications';
import { useState } from 'react';
import { useEffect } from 'react';
import { useView } from '../../statetree/hooks';
import { Trips } from '../trips';
import { Scrollbars } from 'react-custom-scrollbars';

enum TabOpened {
    notification = 1,
    trips = 2
}

export const Client = view(ClientState, ClientVm, [], (props) => {
    const { vm, dispatch } = props;
    const [tabOpened, setTabOpened] = useState(TabOpened.notification);
    const TripsView = useView(Trips); 
    const userId = vm.client ? vm.client.id: ''; 
    useEffect(() => {
        if (tabOpened === TabOpened.trips)
            dispatch(new ClientVmActions.OpenTrips(userId))
    }, [tabOpened, dispatch, userId])

    const birthDate = vm.client && vm.client.account.birthDate ? DateTime.fromMillis(vm.client.account.birthDate).toLocaleString(DateTime.DATE_SHORT) : '';
    const trips = TripsView && vm.client && <Scrollbars autoHeight={true} autoHeightMax={600}><TripsView userId={vm.client.id}/></Scrollbars>;

    return <div className={styles.detail}>
        {vm.inProgress ? <Spinner /> : <React.Fragment><div className={styles.left}>
            <Panel labelId="components.clients.detail.account" icon="person" className={styles.panel} height={400}>
                <PanelRow labelId="components.clients.fields.phone">
                    <span>{vm.client?.account.phone}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.name">
                    <span>{vm.client?.account.name}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.country">
                    <span>{vm.client?.account.country}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.email">
                    <span>{vm.client?.account.email}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.birthDate">
                    <span>{birthDate}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.gender">
                    <Checkbox className={styles.checkbox} labelId="enum.gender.men" checked={vm.client?.account.gender === 'Male'} />
                    <Checkbox className={styles.checkbox} labelId="enum.gender.women" checked={vm.client?.account.gender !== 'Male'} />
                </PanelRow>
            </Panel>
            <Panel labelId="components.clients.detail.stats" icon="chat_timeline" className={styles.panel} height={400}>
                <PanelRow labelId="components.clients.fields.rating">
                    <Rating value={vm.client?.rating.value}></Rating>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.ratingCount">
                    <span>{vm.client?.rating.count}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.numTrips">
                    <span>{vm.client?.stats.numTrips}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.positiveValueCount">
                    <span>{vm.client?.rating.positiveValueCount}</span>
                </PanelRow>
                <PanelRow labelId="components.clients.fields.numFeedbacksGiven">
                    <span>{vm.client?.stats.numFeedbacksGiven}</span>
                </PanelRow>
            </Panel>
        </div>
            <div className={styles.right}>
                <Panel labelId="components.clients.detail.notifications" icon="bell" className={styles.panel} height={600}
                    opened={tabOpened === TabOpened.notification} onOpen={value => {
                        setTabOpened(TabOpened.notification)
                    }}>
                    {vm.client ? <Notifications items={vm.client.notifications} /> : undefined}
                </Panel>
                <Panel labelId="components.clients.detail.trips" icon="passenger_2" className={styles.panel} height={600}
                    opened={tabOpened === TabOpened.trips} onOpen={value => {
                        setTabOpened(TabOpened.trips)
                    }}>
                    <div>{trips}</div>
                </Panel>
            </div>
        </React.Fragment>}
    </div>
});