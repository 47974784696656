import React, { useContext, useEffect, useMemo, useState } from "react";
import { Action } from "./action";
import { StateTree } from "./tree";

type Component<P = any> = React.FunctionComponent<P> | React.ComponentClass<P>;

export const TreeContext = React.createContext<StateTree>(undefined as any);
export const NodeContext = React.createContext<StateTree.Node>(undefined as any);

export function useView<P>(type: Component<P>): React.FunctionComponent<P> | undefined {
    const list = useViews([type]);
    if (list.length > 0)
        return list[0].component;
    return undefined;
}

export function useViews<P>(types: Component<P>[]): { component: React.FunctionComponent<P>, key: string; }[] {

    const node = useContext(NodeContext);
    // eslint-disable-next-line
    const list = useMemo(() => node.findComponents(types), [node.getVersion()]);
    const keys = useMemo(() => ({ map: new Map<React.FunctionComponent<P>, string>(), next: 0 }), []);
    const listWithKeys = useMemo(
        () => list.map(x => ({
            component: x,
            key: (keys.map.get(x) ?? keys.map.set(x, (++keys.next).toString()).get(x) as string)
        })),
        // eslint-disable-next-line
        [list]
    );

    return listWithKeys;
}

export function useChannel<T>(type: new () => T): T {

    const node = useContext(NodeContext);
    const [value, setValue] = useState(node.get<T>(type, true));
    // eslint-disable-next-line
    useEffect(() => node.bind(type, setValue), []);
    return value;
}

export function useDispatch(): (action: Action<any>) => void {

    const node = useContext(NodeContext);
    return (action: Action<any>) => node.execute(action);
}