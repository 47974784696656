import { MainDialog, ToolBarTab } from "../../models/layout";
import { Router } from "../../statetree/router";
import { handler, listener, State, StateType } from "../../statetree/state";
import { ClientsState } from "../clients/clientsState";
import { Page404State } from "../page404/page404State";
import { TripState } from "../trip/tripState";
import { TripsState } from "../trips/tripsState";
import { SettingsState } from "../settings/settingsState";
import { MainVm, MainVmActions } from "./mainVm";
import { ClientState } from "../client/clientState";
import { AppContext } from "../../models/context";
import { DashboardState } from "../dashboard/dashboardState";

export class MainState extends State {

    private page: ToolBarTab | null = null;
    private dialogs: MainDialog[] = [];

    onAttached() {
        this.put(MainVm, new MainVm());
        this.updateChildren();
    }

    onDetached() { }
    static key() { return 'MainState' }

    @listener(Router)
    onRouterChanged(_: Router) {
        this.updateChildren();
    }

    @handler(MainVmActions.AppErrorDialog)
    onAppErrorDialog(action: MainVmActions.AppErrorDialog) {
        if (action.errorMessageId) {
            this.dialogs.push(new MainDialog(action.errorMessageId, action.values))
            this.updateVm();
        }
    }
    @handler(MainVmActions.CloseDialog)
    onCloseDialog(action: MainVmActions.CloseDialog) {
        this.dialogs.pop();
        this.updateVm();
    }

    private updateChildren() {
        const r = this.get(Router);
        if (r.path === "/" || r.match("/", { partial: false })) {
            const location = window && window.location;
            location.replace("/ride");
        } else if (r.match("/ride", { partial: false })) {
            this.put(AppContext, { userId: '' });
            this.attachChild(TripsState, 'uikit.menu.trips', r.path, r.query);
        } else if (r.match("/clients", { partial: false }))
            this.attachChild(ClientsState, 'uikit.menu.clients', r.path, r.query)
        else if (r.match("/settings", { partial: true }))
            this.attachChild(SettingsState, 'uikit.menu.settings', r.path, r.query)
        else if (r.match("/settings", { partial: true }))
            this.attachChild(SettingsState, 'uikit.menu.settings', r.path, r.query)
        else if (r.match("/ride/:rideId", { partial: false }))
            this.attachChild(TripState, 'components.trips.detail.title', r.path, r.query)
        else if (r.match("/clients/:userId", { partial: false }))
            this.attachChild(ClientState, 'components.clients.detail.title', r.path, r.query)
        else if (r.match("/dashboard", { partial: false }))
            this.attachChild(DashboardState, 'uikit.menu.dashboard', r.path, r.query)
        else this.attach(Page404State, { detachOthers: true });
        this.updateVm();
    }

    private attachChild(state: StateType, pageId: string, path: string, query: string) {
        this.page = new ToolBarTab(pageId, path + (query ? '?' + query : ''));
        this.attach(state, { detachOthers: true });
    }

    private updateVm() {
        this.put(MainVm, new MainVm(this.page, [...this.dialogs]));
    }

}