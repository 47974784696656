import { ApiError } from "../../../models/layout";
import { RequestAction } from "../../../statetree/request";

export class TripLogRequest extends RequestAction<TripLog, {}, {}, TripLogDto> {

    constructor(tripId: string, limit: number, nextPageToken: string) {
        const query: any = {tripId};
        if (nextPageToken) query.nextPageToken = nextPageToken;
        else {
            if (limit) query.limit = limit;
        }
        super("GET", "/api/admin/trips/:tripId/events", query);
    }

    fromDto(dto: TripLogDto): TripLog {
        
        return {
            items: dto.items.map(i => i.eventStr),
            nextPageToken: dto.nextPageToken
        }
    }
    mapError(code: string, msg: string): Error | undefined {
        return new ApiError(code, msg);
    }
}
interface TripLog {
    items: string[];
    nextPageToken: string;
}
interface TripLogDto {
    items: { eventStr: string; }[];
    nextPageToken: string;
}