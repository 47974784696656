import styles from './trip.module.scss';
import { Trip, TripCar, TripUser } from "../../models/trips";
import React from 'react';
import { Icon } from '../../uikit/icon';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames/bind';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { tripStatusToIcon } from '../../services/utils/convert';
import { Panel, PanelRow } from '../../uikit/panel';

const cx = classnames.bind(styles);

export interface TripDetailProps {
    trip?: Trip;
    onShowLog: (show: boolean) => void;
}

export const TripDetail: React.FunctionComponent<TripDetailProps> = (props) => {
    const { t, i18n } = useTranslation();
    const [showLog, setShowLog] = useState(false);

    const created = props.trip && DateTime.fromJSDate(props.trip.created);
    const status = (props.trip && props.trip.terminationStatus) || 'active';
    const terminationStatus = t('components.trips.terminationStatus.' + status);
    const icon = tripStatusToIcon(status);
    return <div className={'popup_shadow ' + styles.detail}>
        <PanelRow labelId="components.trips.fields.created">
            <span>{created && created.toLocaleString(DateTime.DATETIME_SHORT)}</span>
            <div className={styles.logBtn} onClick={toggleLog}>
                <Icon button={true}>script</Icon>
            </div>
        </PanelRow>
        <PanelRow labelId="components.trips.fields.terminationStatus">
            <span className={cx('status', status)}>
                <Icon>{icon}</Icon>
                <span className={styles.text}>{terminationStatus}</span>
            </span>
        </PanelRow>
        {props.trip && props.trip.driver && renderDriver(props.trip.driver)}
        {props.trip && props.trip.car && renderCar(props.trip.car)}
        {props.trip && props.trip.clients && props.trip.clients.length ? renderClients(props.trip.clients) : undefined}

    </div>
    function renderDriver(driver: TripUser) {
        return <Panel labelId="components.trips.fields.driver" icon="steering_wheel" className={styles.panel}>
            <PanelRow labelId="models.user.name">
                <span>{driver.name}</span>
            </PanelRow>
        </Panel>
    }

    function renderCar(car: TripCar) {
        const color = (car.color.colorName[i18n.language]) || '';
        const hexColor = car.color.colorDecimal.toString(16);
        return <Panel labelId="components.trips.fields.car" icon="car_2" className={styles.panel}>
            <PanelRow labelId="models.car.model">
                <span>{`${car.brand} ${car.model}`}</span>
            </PanelRow>
            <PanelRow labelId="models.car.year">
                <span>{car.year}</span>
            </PanelRow>
            <PanelRow labelId="models.car.color">
                <span>
                    <span>{color}</span>
                    <span className={styles.circle} style={{ background: `#${hexColor}` }}></span>
                </span>
            </PanelRow>
            <PanelRow labelId="models.car.number">
                <span>{car.number}</span>
            </PanelRow>
        </Panel>

    }
    function renderClients(clients: TripUser[]) {
        return <Panel labelId="components.trips.fields.passengers" icon="passenger_2" className={styles.panel} >
            {clients.map(c => {
                return <PanelRow labelId="models.user.name">
                    <span>{c.name}</span>
                </PanelRow>
            })}
        </Panel>
    }
    function toggleLog() {
        props.onShowLog(!showLog);
        setShowLog(!showLog);
    }
}
