import React from "react";
import classnames from 'classnames/bind';
import styles from './layout.module.scss';
import { MainSideBar } from "./mainSideBar";
import { ToolBar } from "./mainToolBar";
import { cancelAction, Dialog } from "../../uikit/dialog";
import { useTranslation } from "react-i18next";
import { MainDialog, ToolBarTab } from "../../models/layout";
import { User } from "../../models/user";

let cx = classnames.bind(styles);

interface MainLayoutProps {
  tab?: ToolBarTab;
  dialogs?: MainDialog[];
  user: User;
  onLogOut: () => void;
  path: string;
}

export const MainLayout: React.FunctionComponent<MainLayoutProps> = (props) => {
  const { t } = useTranslation();

  return <div className={cx('home')}>
    <MainSideBar user={props.user} onLogOut={props.onLogOut} activePage={props.path}/>
    <div className={styles.pages}>
      <ToolBar activeTab={props.tab} />
      <div className={styles.routes}>
        {props.children}
      </div>
    </div>
    {props.dialogs && props.dialogs.map(d => <Dialog key={d.textId} actions={[cancelAction]} onAction={handleCloseDialog} title={t('uikit.dialog.types.error')}>{t(d.textId, d.value)}</Dialog>)}
  </div>
  function handleCloseDialog(actionId: string) {
    // TODO:
  }
};
