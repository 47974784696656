import { useState } from "react";

export function useHistory() {

    const [history] = useState(new History());
    return history;
}

class History {

    private history: any;

    constructor() {
        this.history = window && window.history;
    }

    get length() {
        return this.history ? this.history.length : 0;
    }
    get state(): any {
        return this.history && this.history.state;
    }
    back() {
        if (this.history)
            this.history.back();
    }
    forward() {
        if (this.history)
            this.history.forvard();
    }
    go(delta: number) {
        if (this.history)
            this.history.go(delta);
    }
    pushState(state: any, url: string) {
        if (this.history) {
            var pushChangeEvent = new CustomEvent("pushState", {
                detail: { state, url }
            });
            this.history.pushState(state, undefined, url);
            document.dispatchEvent(pushChangeEvent);
        }
    }
    replaceState(state: any, url: string) {
        if (this.history) {
            var pushChangeEvent = new CustomEvent("replaceState", {
                detail: { state, url }
            });
            this.history.replaceState(state, undefined, url);
            document.dispatchEvent(pushChangeEvent);
        }
    }
}

export function encodeUrl(pathname: string, search?: { [key: string]: string }, hash?: string) {

    let query = '';
    if (search && typeof search === 'object')
        query = Object.keys(search).reduce((value, key) => {
            value += `&${key}=${search[key]}`;
            return value;
        }, '');
    if (query.length) query = '?' + query.slice(1);
    let _hash = hash ? '#' + hash : '';
    return pathname + query + _hash;
}