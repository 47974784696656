import styles from './settings.module.scss';
import classnames from 'classnames/bind';
import { view } from '../../statetree/view';
import { SettingsState } from './settingsState';
import { SettingsVm } from './settingsVm';

const cx = classnames.bind(styles);

export const Settings = view(SettingsState, SettingsVm, [], (props) => {
    return <div className={cx('page', 'settings-list')}>
        <h1>Settings</h1>
    </div>
});