import { AppContext } from "../../models/context";
import { ApiError } from "../../models/layout";
import { Router } from "../../statetree/router";
import { handler, State } from "../../statetree/state";
import { TripsState } from "../trips/tripsState";
import { ClientRequest } from "./clientRequest";
import { ClientVm, ClientVmActions } from "./clientVm";

export class ClientState extends State {

    onAttached() {
        const router = this.get(Router);
        const match = router.match<{ userId: string }>('/clients/:userId', { partial: false });
        if (match && match.userId)
            this.run(new ClientVmActions.Load(match.userId));
    }

    onDetached() { }

    @handler(ClientVmActions.Load)
    async onLoad(action: ClientVmActions.Load): Promise<void> {
        this.put(ClientVm, this.get(ClientVm).beginLoad());
        try {
            const result = await this.run(new ClientRequest(action.clientId));
            this.put(ClientVm, this.get(ClientVm).endLoad(result));
        } catch (err) {
            const errorMessage = err instanceof ApiError ? err.message : `Login error: ${err.message}`
            console.error(errorMessage)
            this.put(ClientVm, this.get(ClientVm).writeError(errorMessage));
        }
    }
    @handler(ClientVmActions.OpenTrips)
    onOpenTrips(actions: ClientVmActions.OpenTrips) {
        this.put(AppContext, {userId: actions.userId || '' })
        this.attach(TripsState, { detachOthers: true });
    }
}