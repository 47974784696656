import { handler, State } from "../../statetree/state";
import { SettingsVm, SettingsVmActions } from "./settingsVm";

export class SettingsState extends State {

    onAttached() {
        this.run(new SettingsVmActions.Update());
    }

    onDetached() { }

    @handler(SettingsVmActions.Update)
    async onUpdate(_: SettingsVmActions.Update): Promise<SettingsVm> {
        return new SettingsVm();
    }
}