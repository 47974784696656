import { ApiError, AppSettings } from "../../models/layout";
import { Router } from "../../statetree/router";
import { handler, listener, State } from "../../statetree/state";
import { TripLogState } from "./log/tripLogState";
import { TripRequest } from "./tripRequest";
import { TripVm, TripVmActions } from "./tripVm";

export class TripState extends State {

    onAttached() {
        const router = this.get(Router);
        const match = router.match<{ tripId: string }>('/ride/:tripId', { partial: false });
        if (match && match.tripId)
            this.run(new TripVmActions.Load(match.tripId));
        this.onAppSettings(this.get(AppSettings));
    }

    onDetached() { }

    @listener(AppSettings)
    onAppSettings(settings: AppSettings) {
        this.put(TripVm, this.get(TripVm).setColor(settings.colorTheme))
    }

    @handler(TripVmActions.Load)
    async onLoad(action: TripVmActions.Load): Promise<void> {
        this.put(TripVm, this.get(TripVm).beginLoad());
        try {
            const result = await this.run(new TripRequest(action.tripId));
            this.put(TripVm, this.get(TripVm).endLoad(result));
        } catch (err) {
            const errorMessage = err instanceof ApiError ? err.message : `Login error: ${err.message}`
            this.put(TripVm, this.get(TripVm).writeError(errorMessage));
        }
    }
    @handler(TripVmActions.ShowLog)
    async onShowLog(action: TripVmActions.ShowLog): Promise<void> {
        if (action.show)
            this.attach(TripLogState, { detachOthers: true });
        else
            this.detach(TripLogState);
        this.put(TripVm, this.get(TripVm));
    }
}