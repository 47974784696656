import { StateTree } from "../statetree/tree";

export class LocalStorageSerializer implements StateTree.Serializer {

    serialize(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    deserialize(key: string): string | null {
        return localStorage.getItem(key);
    }

}