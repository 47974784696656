import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';
import { Menu, MenuItem } from '../../uikit/menu';
import styles from './layout.module.scss';

interface LangMenuProps {
}

const langItems: MenuItem[] = [
    { id: 'ru', text: 'Русский (RU)' },
    { id: 'uk', text: 'Український (UK)' },
    { id: 'en', text: 'English (EN)' },
];

export const LangMenu: React.FunctionComponent<LangMenuProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { i18n } = useTranslation();
    return <Popover
        isOpen={isOpen}
        positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
        content={<Menu className={styles.langPopup} items={langItems} onSelect={(value) => {
            setIsOpen(false);
            i18n.changeLanguage(value);
            localStorage.setItem('cdAdminLang', value);
        }} selected={i18n.language} />}
        onClickOutside={() => setIsOpen(false)}
        containerClassName="popup_shadow lang-menu"
        padding={8}
    >
        <div><div className={styles.lang} onClick={() => setIsOpen(!isOpen)}>{i18n.language}</div></div>
    </Popover>
}