import { RootState } from "../components/rootState";
import { RequestAction } from "../statetree/request";
import { Action } from "../statetree/action";
import { StateTree } from "../statetree/tree";
import { LocalStorageSerializer } from "./lsSerializer";
import { User } from "../models/user";

export class AppTree extends StateTree {

    constructor() {
        super(RootState, new LocalStorageSerializer());
    }

    getHandler<T>(action: Action<T>): ((action: Action<T>) => Promise<T>) | undefined {
        const handler = super.getHandler(action);
        if (handler)
            return handler;
        if (action instanceof RequestAction)
            return (action) => this.handleRequest(action as any);
        return undefined;
    }

    private async handleRequest<T>(request: RequestAction<T>): Promise<T> {

        const schema = window ? window.location.protocol : "https";
        const host = window ? window.location.host : "sandbox.ontaxi.com.ua";
        const url = request.formatUrl(schema, host);
        const session = this.get(User).session;
        const response = await fetch(url, {
            method: request.method,
            body: getBody(request),
            headers: {
                "Authorization": session ? `Bearer ${session}` : "",
                "Content-Type": "application/json",
                ...(request.headers || {})
            }
        }).catch(err => {
            throw new Error(`Request ${request.constructor.name} failed with ${err}`)
        });

        if (response.status === 401) {
            // TODO: handle unauthorized request
            console.error('response.status: 401');
            const location = window && window.location;
            location.replace('/login');
        }

        const res: { ok: boolean; data: any; error: string; errorMessage: string; } = await response.json();
        if (!res.ok) {
            const e = request.mapError(res.error, res.errorMessage) ?? new Error(`request failed with ${res.error}`);
            throw e;
        }
        return request.fromDto(res.data);

        function getBody(request: RequestAction<T>): string | undefined {

            if (request.body && (request.method === "POST" || request.method === "PUT"))
                return JSON.stringify(request.body);
            return undefined;
        }
    }
}