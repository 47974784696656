import { ColorThemes } from "../../models/layout";
import { Trip } from "../../models/trips";
import { Action } from "../../statetree/action";

export class TripVm {
    constructor(
        readonly color: ColorThemes = ColorThemes.Light,
        readonly trip: Trip| undefined = undefined,
        readonly inProgress: boolean = false,
        readonly error: string = ''
    ) {
    }
    beginLoad() {
        return new TripVm(this.color, this.trip, true);
    }
    endLoad(trip: Trip) {
        return new TripVm(this.color, trip, false);
    }
    writeError(error: string) {
        return new TripVm(this.color, this.trip, false, error);
    }
    setColor(color: ColorThemes) {
        return new TripVm(color, this.trip, this.inProgress, this.error);
    }
}

export namespace TripVmActions {
    export class Load extends Action<TripVm> {
        constructor (
            readonly tripId: string = '',
        ) {super();}
    }
    export class ShowLog extends Action<TripVm> {
        constructor (
            readonly show: boolean = false,
        ) {super();}
    } 
}