import * as React from 'react';
import styles from './link.module.scss';
import { encodeUrl, useHistory } from './history';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface LinkProps {
    className?: string;
    pathname: string;
    search?: { [key: string]: any };
    hash?: string;
    state?: any;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export const Link: React.FunctionComponent<LinkProps> = (props) => {

    const history = useHistory();
    const href = encodeUrl(props.pathname, props.search, props.hash);
    return <span className={cx('link', props.className, { disabled: props.disabled })} onClick={handleClick}><a href={href} className={styles.inner}>{props.children}</a></span>

    function handleClick(event: React.MouseEvent<HTMLSpanElement>) {
        if (!props.disabled && href)
            history.pushState(props.state, href);
        event.preventDefault();
        event.stopPropagation();
    }
}
