import { State } from "../../statetree/state";
import { DashboardVm } from "./dashboardVm";

export class DashboardState extends State {

    onAttached() {
        this.updateChildren();
    }

    private updateChildren() {
        this.put(DashboardVm, new DashboardVm());
    }
}