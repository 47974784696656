import styles from './client.module.scss';
import { ClientNotification } from "../../models/client";
import { Table, TableColumn, TableDataType } from '../../uikit/table';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Icon } from '../../uikit/icon';
import { Link } from '../../uikit/link';
import { Checkbox } from '../../uikit/checkbox';
import { DataController } from '../../uikit/table/dataController';

interface NotificationsProps {
    items: ClientNotification[]
}

export const Notifications: React.FunctionComponent<NotificationsProps> = (props) => {
    const controller = useMemo(() => {
        return new DataController(
            props.items,
            'id',
            false)
    }, [props.items])
    const { t } = useTranslation();

    return <div className={styles.notificatons}>
        <Table className={styles.table} dataController={controller}>
            <TableColumn field="ts" title={t('components.clients.notification.fields.ts')} dataType={TableDataType.DateTime} format={{ dateTimeFormat: DateTime.DATETIME_MED_WITH_SECONDS }} canSort={false} />
            <TableColumn field="ts" title={t('components.clients.notification.fields.trip')} dataType={TableDataType.Custom} canSort={false} renderer={renderTrip} />
            <TableColumn field="read" title={t('components.clients.notification.fields.read')} dataType={TableDataType.Custom} canSort={false} renderer={renderRead} />
        </Table>
    </div>

    function renderTrip(item: ClientNotification) {
        return <Link pathname={"/trips/" + item.tripId}>
            <span>{DateTime.fromMillis(item.tripTs).toLocaleString(DateTime.DATE_MED)}</span>
            <Icon>arrow_right</Icon>
            <span>{item.tripName}</span>
        </Link>
    }
    function renderRead(item: ClientNotification) {
        return <Checkbox checked={item.read}></Checkbox>
    }

}