import { AppContext } from "../../models/context";
import { ApiError } from "../../models/layout";
import { handler, State } from "../../statetree/state";
import { TripsRequest } from "./tripsRequest";
import { TripsVm, TripsVmActions } from "./tripsVm";

export class TripsState extends State {

    onAttached() {
        const appContext = this.get(AppContext); 
        this.run(new TripsVmActions.Load(true, appContext.userId));
    }

    onDetached() { }

    @handler(TripsVmActions.Update)
    async onUpdate(_: TripsVmActions.Update): Promise<TripsVm> {
        return new TripsVm();
    }

    @handler(TripsVmActions.Load)
    async onLoad(action: TripsVmActions.Load): Promise<void> {
        this.put(TripsVm, this.get(TripsVm).beginLoad());
        try {
            const vm = this.get(TripsVm);
            const nextPageToken = (!action.isReload && vm.token) || undefined;  
            const result = await this.run(new TripsRequest(nextPageToken, action.userId || undefined, action.actual));
            this.put(TripsVm, this.get(TripsVm).endLoad(result.items, result.token));
        } catch (err) {
            const errorMessage = err instanceof ApiError ? err.message : `Login error: ${err.message}`
            this.put(TripsVm, this.get(TripsVm).writeError(errorMessage));
        }
    }
}