import * as React from 'react';
import { Link } from '../link';
import classnames from 'classnames/bind';
import styles from './button.module.scss';
const cx = classnames.bind(styles);

export interface ButtonProps {
    className?: string;
    kind?: 'primary' | 'secondary' | 'success' | 'error';
    disabled?: boolean;
    onClick?: (event: React.MouseEvent) => void;
}

interface LinkButtonProps extends ButtonProps {
    pathname: string;
    search?: { [key: string]: any };
    hash?: string;
    state?: any;
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {

    return <button
        type="button"
        className={cx(props.className, 'button', props.kind || 'primary')}
        onClick={props.onClick}
        disabled={props.disabled}>
        {props.children}
    </button>
}

export const LinkButton: React.FunctionComponent<LinkButtonProps> = (props) => {

    return <Link
        className={cx(props.className, 'button', props.kind || 'primary', { disabled: props.disabled })}
        onClick={props.onClick}
        pathname={props.pathname}
        search={props.search}
        hash={props.hash}
        state={props.state}
        disabled={props.disabled}>

        {props.children}
    </Link>
}