import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, useMap, Marker, Polyline } from 'react-leaflet';
import styles from './trip.module.scss';
import { Trip, WayPoint } from "../../models/trips";
import L from 'leaflet';
import { ColorThemes } from "../../models/layout";
import { Icon } from "../../uikit/icon";
import classnames from 'classnames/bind';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { decode as decodePolyline } from '../../services/utils/decodeGooglePolyline';

const cx = classnames.bind(styles);

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const mapApiKey = 'Mfxty3iZi8NmwVW88pHjmI1b0CFrMFvmMDWhzkoUsvA';

export interface TripMapProps {
    trip?: Trip;
    color: ColorThemes;
}

export const TripMap: React.FunctionComponent<TripMapProps> = (props) => {
    const [wpIndex, setWpIndex] = useState(-1);
    const [waypoints, polylines] = useMemo(() => {
        return [
            (props.trip && props.trip.route.waypoints) || [],
            (props.trip && props.trip.route.polylines) || []
        ]
    }, [props.trip]);


    function TileLayer() {
        const map = useMap();
        useEffect(() => {
            const color = props.color === ColorThemes.Dark ? 'night' : 'day';
            const url = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.${color}/{z}/{x}/{y}/256/png?apiKey=${mapApiKey}`;
            new L.TileLayer(
                url,
                {
                    maxNativeZoom: 19,
                    maxZoom: 20,
                    minZoom: 2
                }
            ).addTo(map);
        }, [map])
        return null;
    }

    return <div className={styles.map}>

        <MapContainer className={styles.container} zoom={13} scrollWheelZoom={false} zoomControl={false}>
            <TileLayer />
            <MapControls />
            <TripMarkers />
            <TripPolyLines />
        </MapContainer>
    </div>

    function MapControls() {
        const map = useMap();

        function handleZoom(step: number) {
            const next = map.getZoom() + step;
            if (next < map.getMaxZoom() || next > map.getMinZoom())
                map.setZoom(next);
        }

        function panWaypoynt(step: number) {
            let next = wpIndex + step;
            if (next < 0) next = waypoints.length - 1;
            if (next > waypoints.length - 1) next = 0;
            if (next >= 0 && next < waypoints.length) {
                map.panTo({ lat: waypoints[next].lat || 0, lng: waypoints[next].lon || 0 });
                setWpIndex(next);
            }
        }

        function fitToWp() {
            fitToWaypoints(map, waypoints);
        }

        return <div className={styles.controls}>
            <div className={cx('box', 'top')}>
                <div className={styles.btn} onClick={() => handleZoom(1)}><Icon>add</Icon></div>
                <div className={styles.btn} onClick={() => handleZoom(-1)}><Icon>remove</Icon></div>
            </div>
            <div className={cx('box', 'middle')} onClick={fitToWp}>
                <div className={styles.btn}><Icon>path</Icon></div>
            </div>
            <div className={cx('box', 'down')}>
                <div className={styles.btn} onClick={() => panWaypoynt(1)}><Icon>skip_previous</Icon></div>
                <div className={styles.btn} onClick={() => panWaypoynt(-1)}><Icon>skip_next</Icon></div>
            </div>
        </div>
    }
    function fitToWaypoints(map: L.Map, waypoints: WayPoint[]) {
        if (!waypoints.length) return;
        const bounds: L.LatLngBoundsExpression = waypoints.reduce((bound, wp) => {
            let { lat = 0, lon = 0 } = wp;
            bound[0][0] = Math.min(lat, bound[0][0]);
            bound[0][1] = Math.min(lon, bound[0][1]);
            bound[1][0] = Math.max(lat, bound[1][0]);
            bound[1][1] = Math.max(lon, bound[1][1]);
            return bound;
        }, [[Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER], [-Number.MAX_SAFE_INTEGER, -Number.MAX_SAFE_INTEGER]]);
        if (bounds[0][0] === bounds[1][0] && bounds[0][1] === bounds[1][1])
            map.panTo({ lat: bounds[0][0], lng: bounds[0][1] });
        else {
            map.fitBounds(bounds, { paddingTopLeft: [550, 10] });
        }
    }

    function TripMarkers() {
        const map = useMap();
        useEffect(() => {
            if (wpIndex < 0)
                fitToWaypoints(map, waypoints)
        }, [map])

        return <React.Fragment>
            {waypoints.map((wp, idx) => {
                return <Marker key={idx} position={[wp.lat || 0, wp.lon || 0]} icon={getMarkerIcon(wp.type, wp.color)} ></Marker>
            })}
        </React.Fragment>
    }


    function TripPolyLines() {
        return <React.Fragment>
            {polylines.map((p, idx) => {
                const points = decodePolyline(p.polyline || '');
                const color = p.color === 'primary' ? '#EF8D1A' : '#9AA4BB';
                const dashArray = p.color === 'primary' ? [] : [10];
                return <Polyline key={idx} color={color} weight={5} positions={points} dashArray={dashArray} />
            })}
        </React.Fragment>
    }

    function getMarkerIcon(type: string, color: string) {
        switch (type) {
            case 'from':
                if (color === 'primary')
                    return L.icon({
                        iconUrl: '/images/map/from_primary.svg',
                        iconSize: [36, 36],
                        iconAnchor: [18, 38]
                    });
                else
                    return L.icon({
                        iconUrl: '/images/map/from_secondary.svg',
                        iconSize: [36, 36],
                        iconAnchor: [18, 38]
                    });
            case 'to':
                if (color === 'primary')
                    return L.icon({
                        iconUrl: props.color === ColorThemes.Light ? '/images/map/to_primary.svg' : '/images/map/to_primary_dark.svg',
                        iconSize: [36, 36],
                        iconAnchor: [18, 38]
                    });
                else
                    return L.icon({
                        iconUrl: '/images/map/to_secondary.svg',
                        iconSize: [36, 36],
                        iconAnchor: [18, 38]
                    });
            case 'pickup':
                return L.icon({
                    iconUrl: '/images/map/pickup.svg',
                    iconSize: [36, 36],
                    iconAnchor: [18, 38]
                });
            case 'dropoff':
                return L.icon({
                    iconUrl: '/images/map/dropoff.svg',
                    iconSize: [36, 36],
                    iconAnchor: [18, 38]
                });
        }
    }

}