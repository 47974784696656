import { handler, State } from "../../statetree/state";
import { Page404Vm, Page404VmActions } from "./page404Vm";

export class Page404State extends State {

    onAttached() {
        this.run(new Page404VmActions.Update());
    }

    onDetached() { }

    @handler(Page404VmActions.Update)
    async onUpdate(_: Page404VmActions.Update): Promise<Page404Vm> {
        return new Page404Vm();
    }
}