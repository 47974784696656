export function kmToMile(value: number, precision = 0) {
    return roundFloat(value / 1.609344, precision);
}

export function mileToKm(value: number, precision = 0) {
    return roundFloat(value * 1.609344, precision);
}

export function roundFloat(value: number, precision = 0) {
    let multi = precision ? precision * 10 : 1;
    return Math.round(value * multi) / multi;
}

export function secToTime(value: number) {

    let hours = Math.floor(value / 3600);
    let minutes = Math.floor((value - (hours * 3600)) / 60);
    let seconds = value - (hours * 3600) - (minutes * 60);
    let time: string = (hours < 10 ? "0" : "") + hours;
    time += ':' + (minutes < 10 ? "0" : "") + minutes;
    time += ':' + (seconds < 10 ? "0" : "") + seconds;

    return time;
}
export function tripStatusToIcon(status: string) {
    switch (status) {
        case 'canceled':
            return 'error_filled';
        case 'completed':
            return 'ok_filled';
        case 'active':
            return 'play_circle_filled';
        default:
            return '';
    }
}
export function toHexColor(color: number): string {

    let res = '';
    if (typeof color === 'number') {
        res = color.toString(16);
        if (res.length < 6)
            res = "0".repeat(6 - res.length) + res;
        res = '#' + res;
    }
    return res;
}

export function decodeRouterQuery(query: string): { [key: string]: string } {
    // query like ?phone=+380993722408&id=14
    let _r: { [key: string]: string } = {};
    (query.charAt(0) === '?' ? query.slice(1) : query).split('&').forEach(s => {
        let idx = s.indexOf('=');
        if (idx)
            _r[s.slice(0, idx)] = s.slice(idx + 1)
    })
    return _r;
}
