import styles from './trips.module.scss';
import classnames from 'classnames/bind';
import { TripsVm, TripsVmActions } from './tripsVm';
import { TripsState } from './tripsState';
import { view } from '../../statetree/view';
import { Table, TableColumn, TableDataType } from "../../uikit/table";
import { Spinner } from "../../uikit/spinner";
import { DateTime } from "luxon";
import { Trip } from '../../models/trips';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import { Icon } from '../../uikit/icon';
import { Link } from '../../uikit/link';
import { tripStatusToIcon } from '../../services/utils/convert';
import { DataController } from '../../uikit/table/dataController';

const cx = classnames.bind(styles);


export interface TripsProps {
    userId?: string;
}
export const Trips = view<TripsProps, TripsVm>(TripsState, TripsVm, [], (props) => {
    const { vm, dispatch } = props;
    const handleLoadMore = useCallback(() => {
        dispatch(new TripsVmActions.Load(false, props.userId));
    }, [dispatch, props.userId]);
    const controller = useMemo(() => {
        return new DataController(
            vm.trips,
            'id',
            !!vm.token,
            handleLoadMore)
    }, [vm.trips, vm.token, handleLoadMore]);
    const { i18n, t } = useTranslation();

    return <div className={styles.trips}>
        <div className={styles.toolbar}></div>
        {vm.inProgress ? <div className={styles.spinner}><Spinner /></div> : undefined} 
        <Table className={styles.table} dataController={controller} onRowSelect={handleTripSelect}>
            <TableColumn field="id" title={t('components.trips.fields.id')} dataType={TableDataType.Custom} canSort={true} renderer={renderId} />
            <TableColumn field="created" title={t('components.trips.fields.created')} dataType={TableDataType.DateTime} canSort={true}
                format={{ dateTimeFormat: DateTime.DATETIME_SHORT }} className={styles.created} />
            <TableColumn field="terminationStatus" title={t('components.trips.fields.terminationStatus')} dataType={TableDataType.Custom} canSort={false} renderer={renderTerminationStatus} />
            <TableColumn field="routeFrom" title={t('components.trips.fields.routeFrom')} dataType={TableDataType.Custom} canSort={false} renderer={renderRouteFrom} />
            <TableColumn field="routeTo" title={t('components.trips.fields.routeTo')} dataType={TableDataType.Custom} canSort={false} renderer={renderRouteTo} />
            <TableColumn field="driver" title={t('components.trips.fields.driver')} dataType={TableDataType.Custom} canSort={false} renderer={renderDriver} />
            <TableColumn field="car" title={t('components.trips.fields.car')} dataType={TableDataType.Custom} canSort={false} renderer={renderCar} />
        </Table>
    </div>

    function handleTripSelect(trip: Trip) {
    }
    function renderTerminationStatus(row: Trip) {
        const status = row.terminationStatus || 'active';
        const terminationStatus = t('components.trips.terminationStatus.' + status);
        const icon = tripStatusToIcon(status);
        return <span className={cx('status', status)}>
            <Icon>{icon}</Icon>
            <span className={styles.text}>{terminationStatus}</span>
        </span>
    }
    function renderRouteFrom(trip: Trip) {
        return <React.Fragment>
            {trip.route.waypoints.filter(w => w.type === 'from').map((w, idx) => {
                return <div className={cx('waypoint', w.color)} key={idx + w.color}>
                    <div className={styles.name1}>{w.name1}</div>
                    <div className={styles.name2}>{w.name2}</div>
                </div>
            })}
        </React.Fragment>
    }
    function renderRouteTo(trip: Trip) {
        return <React.Fragment>
            {trip.route.waypoints.filter(w => w.type === 'to').map((w, idx) => {
                return <div className={cx('waypoint', w.color)} key={idx + w.color}>
                    <div className={styles.name1}>{w.name1}</div>
                    <div className={styles.name2}>{w.name2}</div>
                </div>
            })}
        </React.Fragment>
    }
    function renderDriver(trip: Trip) {
        return <div className={styles.driver}>{trip.driver ? trip.driver.name : ''}</div>
    }
    function renderCar(trip: Trip) {

        const color = (trip.car && trip.car.color.colorName[i18n.language]) || '';
        const hexColor = trip.car && trip.car.color.colorDecimal.toString(16);
        return trip.car ? <div className={styles.car}>
            <div className={styles.brand}>{`${trip.car.brand} ${trip.car.model}, ${trip.car.year}`}</div>
            <div className={styles.color}>
                <span>{color}</span>
                <span className={styles.circle} style={{ background: `#${hexColor}` }}></span>
                <span>{trip.car.number}</span>
            </div>
        </div> : undefined;
    }
    function renderId(trip: Trip) {
        return <Link className={styles.link} pathname={`/ride/${trip.id}`}>{trip.id}</Link>
    }
});