import styles from './trip.module.scss';
import { TripVm, TripVmActions } from './tripVm';
import { TripState } from './tripState';
import { TripDetail } from './tripDetail';
import { view } from '../../statetree/view';
import { TripMap } from './tripMap';
import { useView } from '../../statetree/hooks';
import { TripLog as _TripLog } from './log';

export const Trip = view(TripState, TripVm, [], (props) => {
    const { vm, dispatch } = props;
    const TripLog = useView(_TripLog);
    return <div className={styles.trip}>
        {vm.trip && vm.trip.id ? <TripDetail trip={vm.trip} onShowLog={handleShowLog} /> : undefined}
        <TripMap trip={vm.trip} color={vm.color} />
        {TripLog && <TripLog tripId={vm.trip && vm.trip.id} />}
    </div>

    function handleShowLog(show: boolean) {
        dispatch(new TripVmActions.ShowLog(show));
    }

});