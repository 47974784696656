import * as React from 'react';
import styles from './spinner.module.scss';

export const Spinner: React.FunctionComponent<{ className?: string; }> = (props) => (
  <div className={styles.bounce + (props.className ? ' ' + props.className : '')}>
    <div className={styles.bounce1} />
    <div className={styles.bounce2} />
    <div className={styles.bounce3} />
    <div className={styles.bounce4} />
  </div>
);
