import { Action } from "./action";

export class Router {
    private readonly segments: string[];
    constructor(
        readonly schema: string = "",
        readonly host: string = "",
        readonly path: string = "",
        readonly query: string = "",
        readonly state: Object | undefined = undefined
    ) {
        this.segments = (this.path || "/").split("/");
    }

    /* example patterns
        /items/:itemId
        TODO: /items/* 
    */
    match<T = {}>(pattern: string, options?: { partial?: boolean }): Partial<T> | undefined {

        validatePattern(pattern);

        const partial = options?.partial === true;
        const patternSegments = pattern.split("/");
        if (!partial && patternSegments.length !== this.segments.length)
            return undefined;

        const result: any = {};
        const chSemi = ":".charCodeAt(0);
        for (let i = 0; i < patternSegments.length; i++) {
            const x = this.segments[i];
            const y = patternSegments[i];
            if (x.length && y.length && y.charCodeAt(0) === chSemi) {
                const paramName = y.substring(1);
                result[paramName] = x;
                continue;
            }
            if (x !== y)
                return undefined;
        }

        return result;
    }
}

export namespace RouterActions {

    export class Go extends Action<Router>{
        constructor(readonly path: string) {
            super();
        }
    }
}

function validatePattern(path: string) {

    const chSlash = "/".charCodeAt(0);
    const chSemi = ":".charCodeAt(0);
    const cha = "a".charCodeAt(0);
    const chz = "z".charCodeAt(0);
    const chA = "A".charCodeAt(0);
    const chZ = "Z".charCodeAt(0);
    const ch0 = "0".charCodeAt(0);
    const ch9 = "9".charCodeAt(0);
    const chDot = ".".charCodeAt(0);

    if (!path || !path.length)
        throw new Error(`Empty path.`);
    if (path.charCodeAt(0) !== chSlash)
        throw new Error(`Path '${path}' must start with '/'`);
    if (path.length > 1 && path.charCodeAt(path.length - 1) === chSlash)
        throw new Error(`Path '${path}' must not end with '/'`);

    for (let i = 0; i < path.length; i++) {

        const ch = path.charCodeAt(i);
        if (ch === chSlash) {
            if (i > 0) {
                if (path.charCodeAt(i - 1) === chSlash)
                    throw new Error(`Empty section in path ${path}.`);
                if (path.charCodeAt(i - 1) === chSemi)
                    throw new Error(`Empty parameter name in path ${path}.`);
            }
            continue;
        }

        if (ch === chSemi) {
            if (path.charCodeAt(i - 1) !== chSlash)
                throw new Error(`Invalid postion of parameter marker in path ${path}.`);
            continue;
        }
        // eslint-disable-next-line
        if (ch >= chA && ch <= chZ || ch >= cha && ch <= chz || ch >= ch0 && ch <= ch9 || ch === chDot)
            continue;

        throw new Error(`Invalid character in path ${path.charAt(i)}.`);
    }
}