import * as React from 'react';
import styles from './rating.module.scss';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface RatingProps {
    className?: string;
    value?: number;
    maxValue?: number;
    onlyStars?: boolean;
}

export const Rating: React.FunctionComponent<RatingProps> = (props) => {
    let maxValue = props.maxValue ?? 5;
    let value = Math.round(props.value || 0);

    return <div className={cx(props.className, 'rating')}>
        {!props.onlyStars && <span className={styles.value}>{Math.round((props.value || 0) * 100) / 100}</span>}
        {
            Array(maxValue).fill(undefined).map((v, idx) => (
                <svg key={idx} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx({ filled: idx <= value - 1 })}>
                    <path d="M7.00008 1.16675L5.36091 5.03425L1.16675 5.39008L4.35175 8.14925L3.39508 12.2501L7.00008 10.0742L10.6051 12.2501L9.64842 8.14925L12.8334 5.39008L8.63925 5.03425L7.00008 1.16675Z" />
                </svg>
            ))
        }
    </div>

}
