import { Router } from "../statetree/router";
import { State, channel, handler, listener } from "../statetree/state";
import { User } from "../models/user";
import { LoginState } from "./login/loginState";
import { MainState } from "./main/mainState";
import { RootVm, RootVmActions } from "./rootVm";
import { AppSettings } from "../models/layout";
import { AppContext } from "../models/context";

@channel(User, 'User', { serializable: true, public: true })
@channel(AppSettings, 'AppSettings', { serializable: true, public: true })
@channel(AppContext, 'AppContext', { serializable: false, public: true })

export class RootState extends State {

    async onAttached() {
        const settings = this.get(AppSettings);
        this.put(AppSettings, settings.setColorTheme(settings.colorTheme));
        this.updateChildren();
    }

    onDetached() { }

    static key() { return 'RootState' } 

    private updateChildren() {
        const user = this.get(User);
        const router = this.get(Router);
        const forceLogin = router.match('/login');
        if (forceLogin || !user.isLoggedIn()) {
            this.attach(LoginState, { detachOthers: true });
        } else {
            this.attach(MainState, { detachOthers: true });
        }
        this.put(RootVm, new RootVm(user, router.path));
    }

    @handler(RootVmActions.Logout)
    onLogout(action: RootVmActions.Logout) {
        this.put(User, new User());
        this.updateChildren();
    }
    @handler(RootVmActions.SetColorTheme)
    onSetColorTheme(action: RootVmActions.SetColorTheme) {
        const settings = this.get(AppSettings);
        this.put(AppSettings, settings.setColorTheme(action.color));
    }
    @listener(Router)
    onRouterChanged(r: Router) {
        const user = this.get(User);
        this.put(RootVm, new RootVm(user, r.path));
    }
}
