import { Action } from "../../../statetree/action";

interface ItemValue {
    value: string;
    index: number;
}

export class TripLogVm {
    constructor(
        readonly items: ItemValue[] = [],
        readonly token: string | undefined = undefined,
        readonly inProgress: boolean = false,
        readonly error: string = ''
    ) {
        console.log('TripVm created')
    }
    beginLoad() {
        return new TripLogVm(this.items, this.token, true);
    }
    endLoad(items: ItemValue [], token?: string) {
        return new TripLogVm(items, token, false);
    }
    writeError(error: string) {
        return new TripLogVm(this.items, this.token, false, error);
    }
}

export namespace TripLogVmActions {
    export class Load extends Action<TripLogVm> {
        constructor (
            readonly tripId: string = '',
        ) {super();}
    }
}