import React, { useRef, useState } from 'react';
import styles from './panel.module.scss';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Icon } from '../icon';
import { useLayoutEffect } from 'react';

const cx = classnames.bind(styles);

interface PanelProps {
    className?: string;
    label?: string;
    labelId?: string;
    disabled?: boolean;
    icon?: string;
    height?: number;
    opened?: boolean;
    onOpen?: (opened: boolean) => void;
}

export const Panel: React.FunctionComponent<PanelProps> = (props) => {
    const [opened, setOpened] = useState(true);
    const [childHeight, setChildHeight] = useState(0);
    const childRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setOpened(props.opened ?? true)
    }, [props.opened])
    useLayoutEffect(() => {
        if (childRef.current) {
            setChildHeight(childRef.current.clientHeight);
        }
    }, [childRef])

    const { t } = useTranslation();
    const label = props.label || (props.labelId && t(props.labelId));

    return <div className={cx(props.className, { panel: true, disabled: props.disabled, opened })} tabIndex={0} >
        <h4 className={styles.panelTitle} onClick={toggleOpen}>
            {props.icon ? <Icon className={styles.icon}>{props.icon}</Icon> : <div className={styles.icon}></div>}
            <span className={styles.label}>{label}</span>
            <Icon className={styles.down}>chevron_down</Icon>
        </h4>
        <div className={cx('panelText')} style={{ maxHeight: opened ? props.height || childHeight : 0 }}>
            <div className={styles.child} ref={childRef}>
                {props.children}
            </div>
        </div>
    </div>
    function toggleOpen() {
        setOpened(!opened);
        if (props.onOpen) props.onOpen(!opened)
    }

}


interface PanelRowProps {
    className?: string;
    label?: string;
    labelId?: string;
    disabled?: boolean;
}

export const PanelRow: React.FunctionComponent<PanelRowProps> = (props) => {
    const { t } = useTranslation();
    const label = props.label || (props.labelId && t(props.labelId));

    return <div className={cx(props.className, { row: true, disabled: props.disabled })} >
        <label>{label}</label>
        <div className={styles.value}>{props.children}</div>
    </div>

}
