import { Trip } from "../../models/trips";
import { Action } from "../../statetree/action";

export class TripsVm {
    constructor(
        readonly trips: Trip[] = [],
        readonly token: string = '',
        readonly inProgress: boolean = false,
        readonly error: string = ''
    ) { }
    beginLoad() {
        return new TripsVm(this.trips, this.token, true);
    }
    endLoad(trips: Trip[], canLoadNext: string) {
        const _trips = this.token ? this.trips.concat(trips) : trips;
        return new TripsVm(_trips, canLoadNext, false);
    }
    writeError(error: string) {
        return new TripsVm(this.trips, this.token, false, error);
    }
}

export namespace TripsVmActions {
    export class Update extends Action<TripsVm> { }
    export class Load extends Action<TripsVm> {
        constructor(
            readonly isReload: boolean = false,
            readonly userId: string = '',
            readonly actual: boolean | undefined = undefined
        ) { super(); }
    }
}