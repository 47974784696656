import { MainDialog, ToolBarTab } from "../../models/layout";
import { Action } from "../../statetree/action";

export class MainVm {
    constructor(
       readonly activePage: ToolBarTab | null = null,
       readonly dialogs: MainDialog[] = []
    ) { }
}

export namespace MainVmActions {
    export class AppErrorDialog extends Action<MainVm> {
        constructor(readonly errorMessageId?: string, readonly values?: any) { super(); }
    }
    export class CloseDialog extends Action<MainVm> {}
}