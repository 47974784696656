import styles from './clients.module.scss';
import { view } from '../../statetree/view';
import { ClientsState } from './clientsState';
import { ClientsVm, ClientsVmActions } from './clientsVm';
import { useTranslation } from 'react-i18next';
import { Table, TableColumn, TableDataType } from "../../uikit/table";
import { Spinner } from "../../uikit/spinner";
import { DateTime } from "luxon";
import { Client } from '../../models/client';
import { Link } from '../../uikit/link';
import { useMemo } from 'react';
import { DataController } from '../../uikit/table/dataController';
import { useCallback } from 'react';

export const Clients = view(ClientsState, ClientsVm, [], (props) => {
    const { vm, dispatch } = props;
    const handleLoadMore = useCallback(() => {
        dispatch(new ClientsVmActions.Load(false));
    }, [dispatch]);
    const controller = useMemo(() => {
        return new DataController(
            vm.clients,
            'id',
            !!vm.token,
            handleLoadMore)
    }, [vm.clients, vm.token, handleLoadMore])
    const { t } = useTranslation();

    return <div className={styles.clients}>
        <div className={styles.toolbar}></div>
        {vm.inProgress ? <div className={styles.spinner}><Spinner /></div> : undefined}
        <Table className={styles.table} dataController={controller} onRowSelect={handleOrderSelect}>
            <TableColumn field="id" title={t('components.clients.fields.id')} dataType={TableDataType.Custom} canSort={false} renderer={renderClientId} />
            <TableColumn field="created" title={t('components.clients.fields.created')} dataType={TableDataType.DateTime} canSort={true}
                format={{ dateTimeFormat: DateTime.DATETIME_SHORT }} className={styles.created} />
            <TableColumn field="name" title={t('components.clients.fields.name')} dataType={TableDataType.String} canSort={true} />
            <TableColumn field="phone" title={t('components.clients.fields.phone')} dataType={TableDataType.Custom} canSort={true} renderer={renderPhone} />
            <TableColumn field="rating" title={t('components.clients.fields.rating')} dataType={TableDataType.Number} canSort={true} />
            <TableColumn field="numTrips" title={t('components.clients.fields.numTrips')} dataType={TableDataType.Number} canSort={true} />
            <TableColumn field="carsCount" title={t('components.clients.fields.carsCount')} dataType={TableDataType.Number} canSort={true} />
            <TableColumn field="positiveValueCount" title={t('components.clients.fields.positiveValueCount')} dataType={TableDataType.Number} canSort={true} />
        </Table>
    </div>
    function handleOrderSelect(row: Client) { }
    function renderClientId(row: Client) {
        return <Link className={styles.link} pathname={`/clients/${row.id}`}>{row.id}</Link>
    }
    function renderPhone(row: Client) {
        return <span>
            <span className={styles.codePhone}>+380</span>
            {row.phone}
        </span>
    }
    
});
