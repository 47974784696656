import { view } from "../../statetree/view";
import { MainState } from "./mainState";
import { MainVm } from "./mainVm";
import styles from './main.module.scss';
import { useViews } from "../../statetree/hooks";

export const Main = view(MainState, MainVm, [], (props) => {
    const views = useViews([]);
    return <div className={styles.routes}>
        {views.map(view => <view.component key={view.key} />)}
    </div>
});