import { useTranslation } from "react-i18next";
import { view } from "../../statetree/view";
import { LinkButton } from "../../uikit/button";
import styles from './page404.module.scss';
import { Page404State } from "./page404State";
import { Page404Vm } from "./page404Vm";

export const Page404 = view(Page404State, Page404Vm, [], (props) => {

  let { t } = useTranslation();
  return <div className={styles.page404}>
    <h2>
      <span className={styles.four}>4</span>
      <span className={styles.zero}>0</span>
      <span className={styles.four}>4</span>
    </h2>
    <h3>{t('components.404.sorry')}</h3>
    <LinkButton pathname={'/'}>{t('uikit.buttons.home')}</LinkButton>
  </div>

});