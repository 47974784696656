import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Action } from './action';
import { StateDetachedError } from './error';
import { NodeContext, TreeContext, useViews } from './hooks';
import { Router, RouterActions } from './router';
import { State, StateActions, reflect, StateType } from './state';
import { View, viewFind, ViewProps } from './view'

export abstract class StateTree {

    private root: Node | undefined;
    private rootImplicit: Node | undefined;

    constructor(
        private readonly rootStateCtor: StateType,
        public readonly serializer: StateTree.Serializer | undefined = undefined,
    ) { }

    run(elementId: string) {
        if (this.root)
            throw new Error(`Run cannot be called multiple times.`);

        const TreeState = class extends State { };
        this.root = new Node(this, "", TreeState, {
            accept: new Set(),
            vm: Object,
            component: StateTree.render
        }, [[Router, this.createRouter(undefined)]]);
        this.rootImplicit = this.root.attach(this.rootStateCtor);
        this.initRouter();

        ReactDOM.render(
            React.createElement(
                TreeContext.Provider,
                { value: this },
                React.createElement(this.root.getRenderFunction()),
            ),
            document.getElementById(elementId),
        )
    }

    getHandler<T>(action: Action<T>): ((action: Action<T>) => Promise<T>) | undefined {
        if (action instanceof RouterActions.Go)
            return ((action) => this.onRouterGo(action as any) as any);
        return undefined;
    }

    get<T>(type: new () => T): T {
        return this.rootImplicit!.get(type);
    }

    put<T>(type: new () => T, value: T): T {
        return this.rootImplicit!.put(type, value);
    }

    private initRouter() {
        const updateRouter = (event: any) => this.put(Router, this.createRouter(event))
        window.addEventListener("popstate", (event) => updateRouter(event));
        document.addEventListener("pushState", (event) => updateRouter(event));
        document.addEventListener("replaceState", (event) => updateRouter(event));
    }

    private createRouter(event: any): Router {
        const loc = window.location;
        return new Router(loc.protocol, loc.hostname, loc.pathname, loc.search, event && event.detail)
    }

    // TODO: move to state as handler
    private async onRouterGo(action: RouterActions.Go): Promise<Router> {
        window.history.pushState(null, "", action.path);
        this.put(Router, this.createRouter(undefined));
        return this.get(Router);
    }

    private static render() {

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const children = useViews([]);

        return React.createElement(
            React.Fragment,
            undefined,
            children.map(x => React.createElement(x.component, { key: x.key }))
        );
    }
}

export declare namespace StateTree {

    export interface Serializer {
        serialize(key: string, value: string): void;
        deserialize(key: string): string | null;

    }

    export interface Node {
        getVersion(): number;
        get<T>(type: new () => T, selfOnly: boolean): T;
        bind<T>(type: new () => T, listener: (value: T) => void): () => void;
        execute<T, A extends Action<T>>(action: A): Promise<T>;
        findComponents<P>(types: (React.FunctionComponent<P> | React.ComponentClass<P>)[]): React.FunctionComponent<P>[];

    }
}

interface ActionHandler<T = any, A extends Action<T> = Action<T>> {
    (action: A): Promise<T>;
}

class Node implements State.Context, StateTree.Node {

    private readonly state: State;
    private readonly view: View | undefined;
    private parent: Node | undefined;
    private viewParent: Node | undefined;
    private readonly children: Node[] = [];
    private readonly viewChildren: Node[] = [];
    private readonly channels = new Map<Function, Chan>();
    private readonly handlers = new Map<Function, ActionHandler>();
    private readonly listeners = new Map<Function, () => void>();
    private readonly renderFunction = (props?: any) => this.render(props);
    private renderTrigger: (() => void) | undefined;
    private version = 0;
    private detached = false;
    private promise: Promise<any>;
    private completer: { rs: (v: any) => void, rj: (e: Error) => void } | undefined;

    constructor(
        public readonly tree: StateTree,
        public readonly path: string,
        stateType: StateType,
        view?: View,
        chans?: [(new () => Object), any][]
    ) {
        this.state = new stateType();
        this.view = viewFind(stateType) ?? view;
        this.promise = new Promise<any>((rs, rj) => {
            this.completer = {
                rs: rs,
                rj: rj
            };
        });
        if (this.view?.vm) {
            // register vm channel, rerender component on change
            const chan = new Chan(this.view.vm, "", false, undefined);
            chan.bind((value, oldValue) => this.renderTrigger?.());
            this.channels.set(this.view.vm, chan);
        }
        chans?.forEach(([type, value]) => this.channels.set(type, new Chan(type, "", true, undefined, value)));
    }

    getVersion(): number {
        return this.version;
    }

    notDetached(): void {
        if (this.detached)
            throw new StateDetachedError();
    }

    attach(stateType: StateType, input: any = undefined): Node {

        // create node, register in tree
        const parent = this;
        const node = new Node(this.tree, `${this.path}>${stateType.key()}`, stateType);
        parent.addChild(node);
        if (node.view) {
            let current = node.parent;
            let viewParent: Node | undefined;
            while (current) {
                if (current.view) {
                    // first view parent is by default
                    if (!viewParent)
                        viewParent = current;
                    // explicit view parent has priority
                    if (current.view.accept.has(node.view.component)) {
                        viewParent = current;
                        break;
                    }
                }
                current = current.parent;
            }
            // TODO: log if no view parent
            viewParent?.addViewChild(node);
        }

        // initialize
        try {
            const meta = reflect(node.state);
            // register channels
            for (const c of meta.channels) {
                if (node.channels.has(c.type))
                    throw new Error(`Channel of type ${c.type} registered multiple times.`);
                const key = `${node.path}:${c.key}`;
                node.channels.set(c.type, new Chan(
                    c.type as any,
                    key,
                    c.public,
                    c.serializable ? this.tree.serializer : undefined,
                ));
            }
            // register listeners
            for (const l of meta.listeners) {
                if (node.listeners.has(l.type))
                    throw new Error(`Listener of type ${l.type} registered multiple times.`);
                const chan = node.lookupChan(l.type as any);
                node.listeners.set(l.type, chan.bind(l.method.bind(node.state)));
            }
            // register handlers
            for (const h of meta.handlers) {
                if (node.handlers.has(h.type))
                    throw new Error(`Handler of type ${h.type} registered multiple times.`);
                node.handlers.set(h.type, h.method.bind(node.state));
            }
        }
        catch (e) {
            node.parent?.remChild(node);
            node.viewParent?.remViewChild(node);
            throw e;
        }

        State.setContext(node.state, node);
        setImmediate(() => {
            if (node.detached)
                throw new Error(`Node detached ${node.path}.`);
            node.state.onAttached(input);
            node.viewParent?.renderTrigger?.();
        });
        return node;
    }

    detach(output: any, silent = false) {
        if (this.detached)
            return;

        while (this.children.length) {
            const child = this.children[this.children.length - 1];
            child.detach(undefined, true);
        }

        this.detached = true;
        this.channels.forEach(x => x.detach());
        this.listeners.forEach(unbind => unbind());
        this.channels.clear();
        this.listeners.clear();
        this.handlers.clear();
        this.state.onDetached();
        this.viewParent?.remViewChild(this);
        this.parent?.remChild(this);
        if (silent) {
            // TODO: reject when silent
            this.completer?.rs(undefined);
            // this.completer?.rj(new StateDetachedError());
            return;
        }
        this.completer?.rs(output);
    }

    get<T>(type: new () => T, selfOnly: boolean = false): T {
        const chan = this.lookupChan(type, selfOnly);
        return chan.get();
    }

    put<T>(type: new () => T, value: T): T {
        const chan = this.lookupChan(type);
        return chan.put(value);
    }

    bind<T>(type: new () => T, listener: (value: T) => void): () => void {
        const chan = this.lookupChan(type, true);
        return chan.bind((value, oldValue) => listener(value));
    }

    async execute<T, A extends Action<T>>(action: A): Promise<T> {

        if (action instanceof StateActions.Attach) {
            let node = this.children.find(x => x.state.constructor === action.stateType);
            if (!node)
                node = this.attach(action.stateType, action.input);
            if (action.detachOthers && this.children.length > 1)
                this.children
                    .filter(x => x.state.constructor !== action.stateType)
                    .forEach(x => x.detach(undefined, true));
            return node.promise;
        }

        if (action instanceof StateActions.Detach) {
            const node = this.children.find(x => x.state.constructor === action.stateType);
            node?.detach(undefined, true);
            return undefined as any;
        }

        if (action instanceof StateActions.Out) {
            this.detach(action.value);
            return undefined as any;
        }

        const inner = this.lookupHandler<T, A>(action);
        const handler = new ActionHandlerPromise(this, inner);
        return handler.execute(action);
    }

    findComponents<P>(types: (React.FunctionComponent<P> | React.ComponentClass<P>)[]): React.FunctionComponent<P>[] {
        return this.viewChildren
            .filter((node) => !types.length || types.indexOf(node.view!.component) >= 0)
            .map((node) => node?.renderFunction as React.FunctionComponent<P>);
    }

    getRenderFunction(): React.FunctionComponent {
        return this.renderFunction;
    }

    lookupChan<T>(valueType: new () => T, selfOnly = false): Chan<T> {
        const chan = this.lookupChanOrVoid<T>(valueType, selfOnly);
        if (!chan)
            throw new Error(`Channel of type ${valueType.name} is not registered.`);
        return chan;
    }

    lookupChanOrVoid<T>(valueType: new () => T, selfOnly = false): Chan<T> | undefined {
        this.notDetached();
        let node: Node | undefined = this;
        while (node) {
            if (node.channels.has(valueType)) {
                const chan = node.channels.get(valueType) as Chan<T>
                if (chan.public_ || node === this)
                    return chan;
            }
            if (selfOnly)
                break;
            node = node.parent;
        }
        return undefined;
    }

    lookupHandler<T, A extends Action<T>>(action: A): ActionHandler<T, A> {
        const result = this.lookupHandlerOrVoid<T, A>(action);
        if (!result)
            throw new Error(`Cannot resolve handler of type ${action.constructor.name}.`);
        return result;
    }

    lookupHandlerOrVoid<T, A extends Action<T>>(action: A): ActionHandler<T, A> | undefined {
        this.notDetached();
        let node: Node | undefined = this;
        while (node) {
            if (node.handlers.has(action.constructor))
                return node.handlers.get(action.constructor) as ActionHandler<T, A>;
            node = node.parent;
        }
        const handler = this.tree.getHandler<T>(action);
        if (handler)
            return handler;
        return undefined;
    }

    private addChild(node: Node) {
        node.parent = this;
        this.children.push(node);
        this.version++;
    }

    private remChild(node: Node) {
        const idx = this.children.indexOf(node);
        if (idx >= 0)
            this.children.splice(idx, 1);
        node.parent = undefined;
        this.version++;
    }

    private addViewChild(node: Node) {
        node.viewParent = this;
        this.viewChildren.push(node);
        this.version++;
    }

    private remViewChild(node: Node) {
        const idx = this.viewChildren.indexOf(node);
        if (idx >= 0)
            this.viewChildren.splice(idx, 1);
        node.viewParent = undefined;
        this.version++;
    }

    private render(props?: any) {
        const v = this.view;
        if (!v)
            throw new Error(`Cannot render node of state ${this.state.constructor.name} without view.`);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [state, setState] = useState(false);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            this.renderTrigger = () => setState(!state);
            return () => {
                this.renderTrigger = undefined;
            };
        });

        const viewProps: ViewProps<{}, any> = Object.assign({}, props);
        viewProps.vm = this.lookupChan(v.vm, true).get();
        viewProps.dispatch = (action) => this.execute(action);

        return React.createElement(
            NodeContext.Provider,
            { value: this },
            React.createElement(v.component, viewProps),
        );
    }
}

class Chan<T = any> {

    private value: T;
    private readonly bindings: Chan.Listener<T>[] = [];

    constructor(
        readonly type: new () => T,
        readonly key: string,
        readonly public_: boolean,
        readonly serializer?: StateTree.Serializer | undefined,
        initial?: T | undefined,
    ) {
        this.value = this.deserialzie(initial ?? new type());
    }

    get(): T {
        return this.value;
    }

    put(newValue: T): T {
        if (newValue === null || newValue === undefined)
            throw new Error(`Cahnnel cannot contain null or undefined values. Type ${this.type.name}`);
        const oldValue = this.value;
        this.value = newValue;
        if (this.serializer)
            this.serializer.serialize(this.key, JSON.stringify(this.value));
        setImmediate(() => this.trigger(newValue, oldValue));
        return newValue;
    }

    detach() {
        this.bindings.splice(0, this.bindings.length);
    }

    bind(listener: (value: T, oldValue: T) => void): () => void {

        let detached = false;
        let inner: ((value: T, oldValue: T) => void) | undefined = listener;

        this.bindings.push({
            detached: () => detached,
            trigger: (value, oldValue) => inner?.(value, oldValue),
        });

        return () => {
            detached = true;
            inner = undefined;
        };
    }

    private trigger(newValue: T, oldValue: T) {

        for (const binding of this.bindings) {
            if (binding.detached())
                continue;
            binding.trigger(newValue, oldValue);
        }
    }

    private deserialzie(fallback: T): T {
        try {
            const str = this.serializer?.deserialize(this.key);
            if (str)
                return Object.assign(new this.type(), JSON.parse(str));
        }
        catch (e) {
            console.log(`Failed to deserialize channel of type ${this.type.name}.`);
        }
        return fallback;
    }
}

declare namespace Chan {
    export interface Listener<T> {
        detached(): boolean;
        trigger(value: T, oldValue: T): void;
    }
}

class ActionHandlerPromise<T, A extends Action<T>> {

    constructor(
        private readonly node: Node,
        private readonly inner: ActionHandler<T, A>
    ) { }

    async execute(action: A): Promise<T> {

        this.node.notDetached();
        const value = await this.inner(action);
        this.node.notDetached();
        return value;
    }
}