import { ClientAccount, ClientDetail, ClientNotification, ClientRating, ClientStats } from "../../models/client";
import { ApiError } from "../../models/layout";
import { RequestAction } from "../../statetree/request";

export class ClientRequest extends RequestAction<ClientDetail, {}, {}, ClientDto> {

    constructor(userId: string) {
        super("GET", "/api/admin/clients/:userId", { userId });
    }

    fromDto(dto: ClientDto): ClientDetail {
        const account: ClientAccount = new ClientAccount(
            dto.user.account.created,
            dto.user.account.disabled,
            dto.user.account.country,
            dto.user.account.phone,
            dto.user.account.phoneE164,
            dto.user.account.name,
            dto.user.account.surname,
            dto.user.account.email,
            dto.user.account.emailCanonical,
            dto.user.account.emailNew,
            dto.user.account.avatar,
            dto.user.account.birthDate,
            dto.user.account.gender,
            dto.user.account.facebookId,
            dto.user.account.facebookName,
            dto.user.account.appleId,
            dto.user.account.appleName,
            dto.user.account.appleEmail,
            dto.user.account.googleId,
            dto.user.account.googleName,
            dto.user.account.googleEmail
        );
        const stats: ClientStats = new ClientStats(
            dto.user.stats.codeHash,
            dto.user.stats.resetCodeAt,
            dto.user.stats.loginAt,
            dto.user.stats.logoutAt,
            dto.user.stats.numFeedbacksGiven,
            dto.user.stats.numTrips
        );
        const rating: ClientRating = new ClientRating(
            dto.user.rating.value,
            dto.user.rating.count,
            dto.user.rating.positiveValueCount,
            dto.user.rating.values
        );
        return new ClientDetail(
            dto.user.id,
            dto.user.updated,
            account,
            stats,
            rating,
            dto.user.notifications ? dto.user.notifications.items.map(i => new ClientNotification(
                i.id,
                i.type,
                i.tripId,
                i.tripCountry,
                i.requestId,
                i.role,
                i.names,
                i.ts,
                i.tripTs,
                i.tripName,
                i.read
            )): [],
            dto.user.cars
        );
    }
    mapError(code: string, msg: string): Error | undefined {
        return new ApiError(code, msg);
    }
}

interface ClientDto {
    user: {
        id: string;
        version: number;
        updated: number;
        account: {
            created: number;
            disabled: boolean;
            country: string;
            phone: string;
            phoneE164: string;
            name: string;
            surname: string;
            email: string;
            emailCanonical: string;
            emailNew: string;
            avatar: string;
            birthDate: number;
            gender: string;
            facebookId: string;
            facebookName: string;
            appleId: string;
            appleName: string;
            appleEmail: string;
            googleId: string;
            googleName: string;
            googleEmail: string;
        };
        stats: {
            codeHash: string;
            resetCodeAt: number;
            loginAt: number;
            logoutAt: number;
            numFeedbacksGiven: number;
            numTrips: number;
        };
        rating: {
            value: number;
            count: number;
            positiveValueCount: number;
            values: number[];
        };
        notifications: {
            nextId: number;
            items: {
                id: number;
                type: number;
                tripId: string;
                tripCountry: string;
                requestId: string;
                role: number;
                names: string[];
                ts: number;
                tripTs: number;
                tripName: string;
                read: boolean;
            }[]
        },
        cars: {
            items:
            { id: string; }[];
            defaultId: string;
        }
    };
}