import { RequestAction } from "../../statetree/request";
import { User } from "../../models/user";

export class LoginError extends Error {
    constructor(
        readonly code: string,
        readonly errorMessage: string
    ) {
        super(errorMessage || code);
    }
}

export class LoginRequest extends RequestAction<User, {}, {}, LoginDto> {

    constructor(email: string, password: string) {
        super("POST", "/api/admin/account/session", undefined, { email, password });
    }

    fromDto(dto: LoginDto): User {
        return new User(dto.profile.name, dto.profile.email, dto.session);
    }
    mapError(code: string, msg: string): Error | undefined {
        return new LoginError(code, msg);
    }
}

interface LoginDto {
    session: string;
    profile: {
        email: string;
        name: string;
    }
}