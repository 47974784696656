import { Action } from "./action";

const metaKey = "_meta";
const contextKey = "_ctx";

export namespace StateActions {

    export class Attach<I, O, S extends State<I, O>> extends Action<O> {
        constructor(
            readonly stateType: StateType<S>,
            readonly input?: I,
            readonly detachOthers: boolean = false
        ) {
            super();
        }
    }

    export class Detach<I, O, S extends State<I, O>> extends Action<void> {
        constructor(
            readonly stateType: StateType<S>
        ) {
            super();
        }
    }

    export class Out<T> extends Action<void> {
        constructor(
            readonly value: T
        ) {
            super();
        }
    }
}


export abstract class State<I = void, O = void> {

    onAttached(input?: I) { }
    onDetached() { }

    protected get<T>(cls: new () => T): T {
        return State.getContext(this).get(cls);
    }

    protected put<T>(type: new () => T, value: T): T {
        return State.getContext(this).put(type, value);
    }

    protected attach<I, O, S extends State<I, O>>(
        stateType: StateType<S>,
        options?: {
            input?: I,
            detachOthers?: boolean;
        }
    ): Promise<O> {
        return this.run(new StateActions.Attach(stateType, options?.input, options?.detachOthers === true));
    }

    protected detach<I, O, S extends State<I, O>>(stateType: StateType<S>): Promise<void> {
        return this.run(new StateActions.Detach(stateType));
    }

    protected out(output: O): Promise<void> {
        return this.run(new StateActions.Out(output));
    }

    protected run<T>(action: Action<T>): Promise<T> {
        return State.getContext(this).execute(action);
    }

    static getContext<I, O>(component: State<I, O>): State.Context {
        return (component as any)[contextKey];
    }

    static setContext<I, O>(component: State<I, O>, context: State.Context) {
        (component as any)[contextKey] = context;
    }

    static key() {
        return this.name;
    }
}

export declare namespace State {

    export interface Context {
        get<T>(cls: new () => T): T;
        put<T>(type: new () => T, value: T): T;
        execute<T>(action: Action<T>): Promise<T>;
    }

    export interface Meta {
        channels: { type: Function; serializable: boolean; public: boolean; key: string }[];
        handlers: { type: Function; method: Function; chanType?: Function; }[];
        listeners: { type: Function; method: Function; }[];
    }
}

export interface StateType<S = State> {
    new(): S;
    key(): string;
}

export function reflect(state: State): State.Meta {
    return withMeta((state as any).constructor);
}

export function channel<T>(type: new () => T, key: string, options?: { public?: boolean; serializable?: boolean }) {
    return function (target: any) {
        withMeta(target).channels.push({
            type: type,
            key: key || '',
            serializable: options?.serializable === true,
            public: options?.public === true,
        });
    }
}

export function listener<T>(type: new () => T) {
    return function (target: any, name: string) {
        withMeta(target.constructor).listeners.push({
            type: type,
            method: target[name],
        });
    }
}

export function handler<T, A extends Action<T>>(type: new () => A) {
    return function (target: any, name: string) {
        withMeta(target.constructor).handlers.push({
            type: type,
            method: target[name],
        });
    }
}

function withMeta(target: any): State.Meta {
    return target[metaKey] ?? (target[metaKey] = {
        channels: [],
        listeners: [],
        handlers: []
    } as State.Meta);
}