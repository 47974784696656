import { ApiError } from "../../models/layout";
import { handler, State } from "../../statetree/state";
import { ClientsRequest } from "./clientsRequest";
import { ClientsVm, ClientsVmActions } from "./clientsVm";

export class ClientsState extends State {

    onAttached() {
        this.run(new ClientsVmActions.Load());
    }

    onDetached() { }


    @handler(ClientsVmActions.Load)
    async onLoad(action: ClientsVmActions.Load): Promise<void> {
        this.put(ClientsVm, this.get(ClientsVm).beginLoad());
        try {
            const vm = this.get(ClientsVm);
            const nextPageToken = (!action.isReload && vm.token) || undefined;  
            const result = await this.run(new ClientsRequest(nextPageToken));
            this.put(ClientsVm, this.get(ClientsVm).endLoad(result.items, result.token));
        } catch (err) {
            const errorMessage = err instanceof ApiError ? err.message : `Clients reques error: ${err.message}`
            this.put(ClientsVm, this.get(ClientsVm).writeError(errorMessage));
        }
    }
}