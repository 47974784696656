import { CurrentSorting, Sort, TableDataType } from ".";

export class DataController {
    private data: any[];

    constructor(
        public initData: any[],
        readonly idField: string,
        public canNext?: boolean,
        private onLoadMore?: () => void
    ) {
        this.data = initData;
        console.log('data controller created', initData)
    }
    addData(data: any[], canNext: boolean): DataController {
        return new DataController(
            this.data.concat(data),
            this.idField,
            canNext,
            this.onLoadMore
        )
    }
    getSorted(sortColumn: CurrentSorting | undefined) {

        function compareByValue(column: CurrentSorting ) {

			return (a: any, b: any) => {

				let r: number;
				let a_value = a[column.field] || '';
				let b_value = b[column.field] || '';
				switch (column.dataType) {
					case TableDataType.String:
					case TableDataType.Custom:
						r = a_value.localeCompare ? a_value.localeCompare(b_value as string) : 0;
                        break;
					default:
						r = a_value === b_value ? 0 : a_value > b_value ? 1 : -1;
				}

				return column.sort === Sort.Asc ? r : -1 * r;
			}
		}
		return sortColumn && sortColumn.sort !== Sort.None ? [...this.data].sort(compareByValue(sortColumn)): this.data;
	}
    loadMore() {
        if (this.onLoadMore) this.onLoadMore();
    }
}