import * as React from 'react';
import styles from './dialog.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import usePortal from 'react-cool-portal';
import { Button } from '../button';
import { Scrollbars } from 'react-custom-scrollbars';

const cx = classNames.bind(styles);

interface DialogAction {
    id: string;
    title?: string;
    titleId?: string;
    kind?: 'primary' | 'secondary' | 'success' | 'error';
    disabled?: boolean;
    align?: 'left' | 'right' | 'center';
}

export const cancelAction: DialogAction = { id: 'cancel', titleId: 'uikit.buttons.cancel', align: 'right', kind: 'secondary' };
export const okAction: DialogAction = { id: 'ok', titleId: 'uikit.buttons.ok', align: 'center', kind: 'primary' };
export const yesAction: DialogAction = { id: 'yes', titleId: 'uikit.buttons.yes', align: 'right', kind: 'primary' };
export const noAction: DialogAction = { id: 'no', titleId: 'uikit.buttons.no', align: 'right', kind: 'secondary' };

export interface DialogProps {
    className?: string;
    title?: string;
    actions?: DialogAction[];
    style?: any;
    onAction?: (actionId: string) => void;
    children?: string | JSX.Element | undefined;
}

export const Dialog: React.FunctionComponent<DialogProps> = (props) => {
    const { t } = useTranslation();

    function renderButtons(a: DialogAction) {
        return <Button key={a.id} kind={a.kind} disabled={a.disabled} onClick={event => handleAction(event, a)} >{a.titleId ? t(a.titleId) : a.title}</Button>;
    }

    let actions = props.actions ? <div className={styles.actions}>
        {props.actions.filter(a => a.align === 'left').map(renderButtons)}
        <div className={styles.spacer} >
            {props.actions.filter(a => a.align === 'center').map(renderButtons)}
        </div>
        {props.actions.filter(a => !a.align || a.align === 'right').map(renderButtons)}
    </div> : undefined;

    let style = props.style || {};
    return <div className={styles.dialog + ' popup_shadow'} onClick={handleOnClick}>
        {props.title && <h2>{props.title}</h2>}
        <Scrollbars autoHeight={true} autoHeightMax={480} style={{ ...style }} >
            <div className={styles.content}>
                {props.children}
            </div>
        </Scrollbars>
        {actions}
    </div>;

    function handleAction(event: React.MouseEvent, action: DialogAction) {
        if (props.onAction)
            props.onAction(action.id);
    }
    function handleOnClick(event: React.MouseEvent) {
        event.stopPropagation();
    }
}

export const useModal = (options: any = {}) => {
    const { Portal, isShow, hide, ...rest } = usePortal({
        ...options,
        defaultShow: false
    });

    const Modal = React.useCallback(
        (props: DialogProps) => (
            <Portal>
                <div className={cx({ modal: true, opened: isShow })} tabIndex={-1} onClick={hide}>
                    <Dialog {...props} />
                </div>
            </Portal>
        ),
        [isShow, Portal, hide]
    );

    return { Modal, isShow, hide, ...rest };
};
