import { Action } from "../statetree/action";

export class User {
    constructor(
        readonly name = '',
        readonly email = '',
        readonly session = ''
    ) { }

    setLoggedIn(name: string, email: string, session: string ): User {
        return new User(name ?? this.name, email, session);
    }

    setLoggedOut(): User {
        return new User('', '', '');
    }

    isLoggedIn(): boolean {
        return this.session.length > 0;
    }
}

export namespace UserActions {

    export class Login extends Action<User> {
        constructor(
            readonly email: string = "",

        ) { super(); }
    }

    export class Logout extends Action<User>{ }
}