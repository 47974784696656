import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './layout.module.scss';
import { menuItems, settingsMenuItem, MenuItem, userMenuItem } from './mainMenuItems';
import classNames from 'classnames/bind';
import { Link } from '../../uikit/link';
import { Popover } from 'react-tiny-popover'
import { Button } from '../../uikit/button';
import { User } from '../../models/user';

const cx = classNames.bind(styles);

interface SideBarProps {
  activePage?: string;
  user: User;
  onLogOut: () => void;
}

export const MainSideBar: React.FunctionComponent<SideBarProps> = (props) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  let { t } = useTranslation();
  return <nav className={styles.sideBar}>
    <ul className={cx('menu', 'up')}>
      {menuItems.map((item, idx) => <li key={idx}>{renderItem(item)}</li>)}
    </ul>
    <div className={styles.expander} />
    <ul className={cx('menu', 'down')}>
      <li>{renderItem(settingsMenuItem)}</li>
      <li>
        <Popover
          isOpen={userMenuOpen}
          positions={['right', 'bottom']} // preferred positions by priority
          content={<div className={styles.userMenu}>
            <div className={styles.userName}>{props.user.name}</div>
            <div className={styles.email}>{props.user.email}</div>
            <Button kind="secondary" onClick={handleLogOut} >{t('uikit.buttons.logout')}</Button>
          </div>}
          onClickOutside={() => setUserMenuOpen(false)}
          containerClassName="popup_shadow"
          padding={8}
        >
          <div onClick={() => {
            setUserMenuOpen(!userMenuOpen)
          }}>{renderClickItem(userMenuItem)}</div>
        </Popover>
      </li>
    </ul>
  </nav>;

  function renderItem(item: MenuItem) {
    return <Link className={cx({ menuItem: true, active: item.url === props.activePage, isVisible: item.active })} disabled={!item.active} pathname={item.url}>
      <div className={styles.itemContent}>
        {item.icon}
        {item.titleId && <div className={styles.title}>{t(item.titleId)}</div>}
      </div>
    </Link>
  }
  function renderClickItem(item: MenuItem) {
    return <span className={cx('menuItem', 'isVisible', 'pointer')}><div className={styles.itemContent}>
      {item.icon}
      {item.titleId && <div className={styles.title}>{t(item.titleId)}</div>}
    </div></span>
  }

  function handleLogOut() {
    props.onLogOut();
  }
}