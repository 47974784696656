import React from "react";
import { Action } from "./action";
import { State } from "./state";

const views = new Map<Function, View>();
const components = new Map<Function, Function>();

export type ViewProps<P, VM> = P & { vm: VM; dispatch: (action: Action<any>) => void };
type Component<P = any> = React.FunctionComponent<P> | React.ComponentClass<P>;

export interface View {
    accept: Set<Component>,
    vm: new () => Object,
    component: Component;
}

export function view<P = {}, VM = {}, T = Component<ViewProps<P, VM>>>(
    stateType: new () => State,
    vmType: new () => VM,
    accept: Component[],
    component: T
): Component<P> {
    const componentFn = (component as any) as Function;
    if (components.has(componentFn))
        throw new Error(`Compoonent ${componentFn.name} already registered for ${components.get(componentFn)?.name}.`);
    if (views.has(stateType))
        throw new Error(`State ${stateType.name} already registered for ${views.get(stateType)?.component.name}.`);

    components.set(component as any, stateType);
    views.set(stateType, {
        accept: new Set(accept || []),
        vm: vmType,
        component: component as any
    });
    return (component as any) as Component<P>;
}

export function viewFind(stateClass: new () => State): View | undefined {

    return views.get(stateClass);
}