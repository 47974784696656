import { Action } from "./action";

export abstract class RequestAction<T, P = {}, B = {}, DTO = {}> extends Action<T> {
    constructor(
        readonly method: "GET" | "PUT" | "POST" | "DELETE",
        readonly path: string,
        readonly params?: P | undefined,
        readonly body?: B | undefined,
        readonly headers?: any | undefined
    ) {
        super();
        validatePath(path);
    }

    abstract fromDto(dto: DTO): T;
    mapError(code: string, msg: string): Error | undefined {
        return undefined;
    }

    formatUrl(schema: string, host: string): string {

        const params: any = this.params;
        if (!params)
            return `${schema}//${host}${this.path}`;

        // path
        const usedKeys: { [key: string]: boolean } = {};
        const segments = this.path.split("/");
        for (let i = 0; i < segments.length; i++) {
            const segment = segments[i];
            if (!segment || segment.charAt(0) !== ":")
                continue;
            const paramName = segment.substring(1);
            if (!paramName)
                throw new Error(`Cannot format URL for ${this.constructor.name}. Empty parameter in path.`);
            const paramValue = params[paramName];
            if (paramValue === null || paramValue === undefined)
                throw new Error(`Cannot format URL for ${this.constructor.name}. Empty param value ${paramName}.`);
            segments[i] = paramValue.toString(); // TODO: format arrays, dates?
            usedKeys[paramName] = true;
        }
        const path = segments.join("/");

        // query
        let query = "";
        for (const key in params) {
            if (usedKeys[key])
                continue;
            const value = params[key];
            const valueStr = (value === null || value === undefined) ? "" : value.toString();
            query += query.length ? "&" : "?";
            query += `${encodeURIComponent(key)}=${encodeURIComponent(valueStr)}`;
        }

        return `${schema}//${host}${path}${query}`;
    }
}

function validatePath(path: string, noParams: boolean = false) {

    const chSlash = "/".charCodeAt(0);
    const chSemi = ":".charCodeAt(0);
    const cha = "a".charCodeAt(0);
    const chz = "z".charCodeAt(0);
    const chA = "A".charCodeAt(0);
    const chZ = "Z".charCodeAt(0);
    const ch0 = "0".charCodeAt(0);
    const ch9 = "9".charCodeAt(0);
    const chDot = ".".charCodeAt(0);

    if (!path || !path.length)
        throw new Error(`Empty path.`);
    if (path.charCodeAt(0) !== chSlash)
        throw new Error(`Path '${path}' must start with '/'`);
    if (path.length > 1 && path.charCodeAt(path.length - 1) === chSlash)
        throw new Error(`Path '${path}' must not end with '/'`);

    for (let i = 0; i < path.length; i++) {

        const ch = path.charCodeAt(i);
        if (ch === chSlash) {
            if (i > 0) {
                if (path.charCodeAt(i - 1) === chSlash)
                    throw new Error(`Empty section in path ${path}.`);
                if (path.charCodeAt(i - 1) === chSemi)
                    throw new Error(`Empty parameter name in path ${path}.`);
            }
            continue;
        }

        if (ch === chSemi && !noParams) {
            if (path.charCodeAt(i - 1) !== chSlash)
                throw new Error(`Invalid postion of parameter marker in path ${path}.`);
            continue;
        }
        // eslint-disable-next-line
        if (ch >= chA && ch <= chZ || ch >= cha && ch <= chz || ch >= ch0 && ch <= ch9 || ch === chDot)
            continue;

        throw new Error(`Invalid character in path ${path.charAt(i)}.`);
    }
}