import { Client } from "../../models/client";
import { ApiError } from "../../models/layout";
import { RequestAction } from "../../statetree/request";

export class ClientsRequest extends RequestAction<ClientsResult, {}, {}, ClientsDto> {

    constructor(nextPageToken: string | undefined) {
        const query: any = {};
        if (nextPageToken) query.nextPageToken = nextPageToken;
        super("GET", "/api/admin/clients", query);
    }

    fromDto(dto: ClientsDto): ClientsResult {
        return {
            items: dto.items.map(c => {
                return new Client(c.id, c.name, c.phone, c.rating, c.positiveValueCount, c.numTrips, c.created, c.carsCount);
            }),
            token: dto.nextPageToken || '' 
        }
    }
    mapError(code: string, msg: string): Error | undefined {
        return new ApiError(code, msg);
    }
}

interface ClientsResult {
    items: Client[];
    token: string;
}

interface ClientsDto {
    items: {
        id: string;
        name: string;
        phone: string;
        rating: number;
        positiveValueCount: number;
        numTrips: number;
        created: number;
        carsCount: number;
    }[];
    nextPageToken: string;
}